export function groupBy<V, K>(items: V[], keyMapper: (v: V) => K): Map<K, V[]> {
    const result = new Map<K, V[]>();

    for (const item of items) {
        const key = keyMapper(item);

        if (!result.has(key)) {
            result.set(key, [item]);
        } else {
            result.get(key)!.push(item);
        }
    }
    return result;
}
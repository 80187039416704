import { MediaType } from "../constants";

export function extensionToMediaType(ext: string): MediaType {
    switch (ext) {
    case "png":
    case "jpg": {
        return MediaType.image;
    }
    case "mp4": {
        return MediaType.video;
    }
    case "mp3":
    case "aac":
    case "wav": {
        return MediaType.audio;
    }
    default: {
        return MediaType.file;
    }
    }
}

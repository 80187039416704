import React, { useCallback, useState } from "react";
import { IComplaintCardProps } from "../props";
import { ComplaintStatus, IComplaintFull } from "../../../../models";
import { useAppSelector } from "../../../../hooks";
import { ComplainFinishedStatus } from "../../../../services";
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, Select, SelectChangeEvent, Input, MenuItem, Typography, DialogTitle, Stack } from "@mui/material";
import { IAppResponse } from "../../../../utils";
import { ComplaintStatusChip, statusLabelFrom } from "../../../../components/complaint-status-chip.component";

export interface IComplaintCardButtonsProps {
    complaint: IComplaintFull,
    actionsLoading: boolean,
    actions: IComplaintCardProps["actions"],
}

const CLOSED_COMPLAINT_STATUS: ComplainFinishedStatus[] = [
    ComplaintStatus.checked,
    ComplaintStatus.violatorFined,
    ComplaintStatus.violatorNotFound,
];

const ComplaintCloseButton = (props: { close: IComplaintCardProps["actions"]["close"], actionsLoading: boolean }) => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [status, setStatus] = useState<ComplainFinishedStatus>(ComplaintStatus.checked);
    const [result, setResult] = useState<IAppResponse<boolean> | null>(null);

    const openModal = useCallback(() => {
        setResult(null);
        setIsModalOpened(true);
    }, [setIsModalOpened]);

    const closeModal = useCallback(() => {
        setIsModalOpened(false);
    }, [setIsModalOpened]);

    const closeComplaint = useCallback(async () => {
        try {
            const res = await props.close(status);
            
            if (res.result) {
                setIsModalOpened(false);
            } else {
                setResult(res);
            }
        } catch (e) {
            console.error(e);
        }
    }, [status, setIsModalOpened, setResult]);

    const onStatusSelectChanged = useCallback((e: SelectChangeEvent<ComplainFinishedStatus>) => {
        setStatus(e.target.value as ComplainFinishedStatus);
    }, [setStatus]);

    return (
        <>
            <Dialog
                open={isModalOpened}
                onClose={closeModal}
            >
                <DialogTitle>Закрытие жалобы</DialogTitle>
                <DialogContent id="sign-out-dialog">
                    <DialogContentText>В течении 7 дней, подающий жалобу может возобновить ее рассмотрение</DialogContentText>
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ pt: 1 }}>
                        <Typography variant="body2">Итоговый статус жалобы:</Typography>
                        <Select
                            labelId="status-label"
                            label="Итоговый статус"
                            id="status-select"
                            value={status}
                            onChange={onStatusSelectChanged}
                            input={<Input disableUnderline id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <ComplaintStatusChip size="small" key={selected} status={selected} />
                            )}
                            error={result !== null && result.result === undefined && result?.error !== undefined}
                        >
                            {CLOSED_COMPLAINT_STATUS.map((status) => (
                                <MenuItem
                                    key={status}
                                    value={status}
                                >
                                    {statusLabelFrom(status)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                    {
                        result !== null && result.result === undefined ? (
                            <Typography variant="caption" color="error">{result.error.mainMessage}</Typography>
                        ) : undefined
                    }
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeModal}>Отмена</Button>
                    <Button variant="contained" color="secondary" onClick={closeComplaint} autoFocus>Закрыть</Button>
                </DialogActions>
            </Dialog>
            <Button variant="contained" color="secondary" onClick={openModal} disabled={props.actionsLoading}>Закрыть жалобу</Button>
        </>
    );
};

export const ComplaintCardButtons = (props: IComplaintCardButtonsProps) => {
    const myUserId = useAppSelector(state => state.session.token !== undefined ? state.session.userId : null);
    const complaint = props.complaint;

    return (
        <>
            <Button variant="text" color="warning" disabled={props.actionsLoading}>Пожаловаться</Button>
            <Button variant="text" color="primary" disabled={props.actionsLoading}>Экспорт</Button>
            {
                complaint.assignedUserId !== undefined && complaint.assignedUserId === myUserId && !CLOSED_COMPLAINT_STATUS.includes(complaint.status as number) ? (
                    <ComplaintCloseButton close={props.actions.close} actionsLoading={props.actionsLoading} />
                ) : undefined
            }
            {
                complaint.assignedUserId === undefined && complaint.status === ComplaintStatus.unchecked ? (
                    <Button variant="contained" color="secondary" onClick={props.actions.assign} disabled={props.actionsLoading}>Начать рассмотрение</Button>
                ) : undefined
            }
        </>
    );
};

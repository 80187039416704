import React, { useCallback, useContext, useMemo } from "react";
import { useAppSelector, useTitle } from "../../hooks";
import { Accesses, AppRoutes } from "../../constants";
import { IComplaintsService, IGetComplaintsRequest, ServicesContext } from "../../services";
import { GetComplaintsPage, IGetComplaintsPageProps } from "./get-complaints.page";
import { useNavigate } from "react-router-dom";
import { IComplaintShort } from "../../models";

function complaintsLoader(complaintsService: IComplaintsService): IGetComplaintsPageProps["loader"] {
    return async (req: IGetComplaintsRequest) => {
        const complaintsRes = await complaintsService.getComplaints(req);

        const typesRes = await complaintsService.getComplaintTypes();

        if (complaintsRes.result !== undefined) {
            return {
                result: {
                    complaints: complaintsRes.result.complaints,
                    total: complaintsRes.result.total,
                    users: undefined,
                    types: typesRes.result !== undefined ? Object.fromEntries(typesRes.result.flatMap(group => group.types).map(type => [type.id, type])) : {},
                },
            };
        }

        return complaintsRes;
    };
}

export const HomePage = () => {
    useTitle("Главная - Активный гражданин");

    const navigate = useNavigate();

    const session = useAppSelector(state => state.session);

    const { complaintsService } = useContext(ServicesContext);

    const canViewAllComplaints = session.token !== undefined && session.accesses.includes(Accesses.getComplaints);

    const loader = useMemo(() => {
        if (canViewAllComplaints) {
            return complaintsLoader(complaintsService);
        } else {
            return null;
        }
    }, [canViewAllComplaints]);

    const onComplaintRowClicked = useCallback((complaintId: IComplaintShort["id"]) => {
        navigate(AppRoutes.complaint(complaintId));
    }, [navigate]);

    return (
        <>
            {
                loader !== null
                    ? (
                        <GetComplaintsPage
                            title={"Жалобы"}
                            loader={loader}
                            complaintRowClicked={onComplaintRowClicked}
                        />
                    ) : undefined
            }
        </>
    );
};

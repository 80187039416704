import { IRestorePasswordRequest, IUsersService } from "../../../services";
import { ActionFunction } from "react-router-dom";

export function restorePasswordAction(usersService: IUsersService): ActionFunction {
    return async (args) => {
        const data: IRestorePasswordRequest = await args.request.json();
        const res = await usersService.restorePassword(data);
        return res;
    };
}

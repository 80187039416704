import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MediaType } from "../../constants";

type ICurrentFullscreenMediaState = {
    media: {
        src: string;
        name: string;
        type: MediaType.image | MediaType.video
    }
};

export type IFullscreenMediaState = ICurrentFullscreenMediaState | {
    media?: undefined
};

const FULLSCREEN_MEDIA_INITIAL_STATE: IFullscreenMediaState = {};

export const fullscreenMediaSlice = createSlice({
    name: "fullscreenMedia",
    initialState: FULLSCREEN_MEDIA_INITIAL_STATE as IFullscreenMediaState,
    reducers: {
        setMedia(state, action: PayloadAction<ICurrentFullscreenMediaState["media"]>) {
            state.media = action.payload;
        },
        clear(state) {
            delete state.media;
        }
    }
});

export const fullscreenMediaActions = fullscreenMediaSlice.actions;
export const fullscreenMediaReducer = fullscreenMediaSlice.reducer;

import { ComplaintStatus, IComplaintType as IComplaintType, IComplaintFull, IComplaintShort, IComplaintTypeGroup, ISessionToken, IUser } from "../models";
import { IAppResponse, SOMETHING_HAPPENED_WRONG_ERROR, parseErrors } from "../utils";

export enum ComplaintsOrderStrategy {
    byDate = 0,
    byAddress = 1,
    byUsername = 2,
    byCreated = 3,
}

export interface IGetComplaintsRequest {
    skip: number,
    take: number,
    orderBy: ComplaintsOrderStrategy,
    reverse: boolean,
    assignId?: IUser["id"],
    statusFilter?: ComplaintStatus,
    textFilter?: string,
    start?: Date,
    end?: Date,
}

export interface IGetComplaintsResponse {
    complaints: IComplaintShort[];
    total: number;
}

export interface IGetComplaintsChangesRequest {
    skip: number,
    take: number,
    start: Date,
    end: Date,
}

export interface IComplaintStatusChanges {
    id: string;
    complaintId: IComplaintShort["id"];
    dt: Date;
    beforeStatus: ComplaintStatus;
    afterStatus: ComplaintStatus;
}

export interface IGetComplaintsChangesResponse {
    changes: IComplaintStatusChanges[];
}

export type ComplainFinishedStatus = ComplaintStatus.checked | ComplaintStatus.violatorFined | ComplaintStatus.violatorNotFound

export interface IComplaintsService {
    getComplaintTypes(): Promise<IAppResponse<IComplaintTypeGroup[]>>;
    getComplaintType(id: IComplaintType["id"]): Promise<IAppResponse<IComplaintType | null>>;
    assignComplaint(complaintId: IComplaintFull["id"], assignedId: IUser["id"] | null): Promise<IAppResponse<IUser>>;
    closeComplaint(complaintId: IComplaintFull["id"], status: ComplainFinishedStatus): Promise<IAppResponse<boolean>>;
    getComplaints(req: IGetComplaintsRequest): Promise<IAppResponse<IGetComplaintsResponse>>;
    getComplaintsChanges(req: IGetComplaintsChangesRequest): Promise<IAppResponse<IGetComplaintsChangesResponse>>;
    getComplaintsByIds(ids: IComplaintShort["id"][]): Promise<IAppResponse<IComplaintFull[]>>;
    getComplaint(id: IComplaintShort["id"]): Promise<IAppResponse<IComplaintFull | null>>;
}

export class ComplaintsService implements IComplaintsService {
    private readonly _apiUsersPath;

    private readonly _defaultHeaders: HeadersInit;

    private readonly _token: ISessionToken | undefined;

    constructor(token: ISessionToken | undefined) {
        this._apiUsersPath = `${process.env.REACT_APP_API_PATH}/complaints`;
        this._defaultHeaders = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        };
        this._token = token;
        if (this._token !== undefined) {
            this._defaultHeaders["Authorization"] = `${this._token.tokenType} ${this._token.token}`;
        }
    }

    async getComplaintTypes(): Promise<any> {
        try {
            const res = await fetch(`${this._apiUsersPath}/types`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: data.result.map((entry: any) => ({
                        id: entry.id,
                        title: entry.title,
                        types: entry.types.map((typeEntry: any) => ({
                            id: typeEntry.id,
                            title: typeEntry.title,
                            description: typeEntry.description,
                            fields: typeEntry.fields,
                            maxMedia: typeEntry.maxMedia,
                            allowedMedia: typeEntry.allowedMedia,
                            allowedPoints: typeEntry.allowedPoint,
                            icon: typeEntry.icon ?? undefined,
                        })),
                        icon: entry.icon ?? undefined,
                    }) as IComplaintTypeGroup),
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async getComplaintType(typeId: IComplaintType["id"]): Promise<IAppResponse<IComplaintType | null>> {
        try {
            const res = await fetch(`${this._apiUsersPath}/types/${typeId}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.status === 404) {
                return {
                    result: null,
                };
            }

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: {
                        id: data.result.id,
                        title: data.result.title,
                        description: data.result.description,
                        fields: data.result.fields,
                        maxMedia: data.result.maxMedia,
                        allowedMedia: data.result.allowedMedia,
                        allowedPoints: data.result.allowedPoint,
                        icon: data.result.icon ?? undefined,
                    },
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async getComplaints(req: IGetComplaintsRequest, onlyMine = false): Promise<IAppResponse<IGetComplaintsResponse>> {
        const query = new URLSearchParams({
            skip: req.skip.toString(),
            take: req.take.toString(),
            order_by: req.orderBy.toString(),
            reverse: req.reverse.toString(),
        });

        if (req.statusFilter !== undefined) {
            query.append("status_filter", req.statusFilter.toString());
        }
        if (req.textFilter !== undefined) {
            query.append("text_filter", req.textFilter.toString());
        }
        if (req.assignId !== undefined) {
            query.append("assign_id", req.assignId.toString());
        }
        if (req.start !== undefined) {
            query.append("start", req.start.toISOString());
        }
        if (req.end !== undefined) {
            query.append("end", req.end.toISOString());
        }

        try {
            const res = await fetch(`${this._apiUsersPath}${onlyMine ? "/my" : "/"}?${query.toString()}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: {
                        complaints: data.result.map((entry: any) => ({
                            id: entry.id,
                            complaintTypeId: entry.complaint_type_id,
                            userId: entry.user_id,
                            address: entry.address,
                            comment: entry.comment,
                            status: entry.status,
                            createdAt: new Date(entry.created_at),
                            updatedAt: entry.updated_at ? new Date(entry.updated_at) : undefined,
                        }) as IComplaintShort),
                        total: data.count,
                    }, 
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async getComplaintsChanges(req: IGetComplaintsChangesRequest): Promise<IAppResponse<IGetComplaintsChangesResponse>> {
        const query = new URLSearchParams({
            skip: req.skip.toString(),
            take: req.take.toString(),
            start: req.start.toISOString(),
            end: req.end.toISOString(),
        });

        try {
            const res = await fetch(`${this._apiUsersPath}/status-changes?${query.toString()}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: {
                        changes: data.result.map((entry: any) => ({
                            id: entry.id,
                            complaintId: entry.complaint_id,
                            dt: new Date(entry.dt),
                            beforeStatus: entry.before_status,
                            afterStatus: entry.after_status
                        }))
                    }, 
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async getComplaintsByIds(ids: IComplaintShort["id"][]): Promise<IAppResponse<IComplaintFull[]>> {
        try {
            const query = new URLSearchParams();
            ids.forEach(id => query.append("complaint_id", id));

            const res = await fetch(`${this._apiUsersPath}/ids?${query.toString()}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: data.result.map((entry: any) => ({
                        id: entry.id,
                        complaintTypeId: entry.complaint_type_id,
                        userId: entry.user_id,
                        address: entry.address,
                        comment: entry.comment,
                        status: entry.status,
                        createdAt: new Date(entry.created_at),
                        updatedAt: entry.updated_at ? new Date(entry.updated_at) : undefined,
                        closedAt: entry.closed_at ? new Date(entry.closed_at) : undefined,
                        closedConfirmed: entry.closed_confirmed,
                        relatedCheckpointId: entry.related_checkpoint_id,
                        fields: entry.fields,
                        lat: entry.lat,
                        lon: entry.lon,
                        userLat: entry.user_lat,
                        userLon: entry.user_lon,
                        assignedUserId: entry.assigned_id,
                        medias: entry.medias,
                        chatId: entry.chat_id,
                    })), 
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }    
    }

    async getComplaint(id: string): Promise<IAppResponse<IComplaintFull | null>> {
        try {
            const res = await fetch(`${this._apiUsersPath}/${id}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.status === 404) {
                return {
                    result: null,
                };
            }

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: {
                        id: data.result.id,
                        complaintTypeId: data.result.complaint_type_id,
                        userId: data.result.user_id,
                        address: data.result.address,
                        comment: data.result.comment,
                        status: data.result.status,
                        createdAt: new Date(data.result.created_at),
                        updatedAt: data.result.updated_at ? new Date(data.result.updated_at) : undefined,
                        closedAt: data.result.closed_at ? new Date(data.result.closed_at) : undefined,
                        closedConfirmed: data.result.closed_confirmed,
                        relatedCheckpointId: data.result.related_checkpoint_id,
                        fields: data.result.fields,
                        lat: data.result.lat,
                        lon: data.result.lon,
                        userLat: data.result.user_lat,
                        userLon: data.result.user_lon,
                        assignedUserId: data.result.assigned_id,
                        medias: data.result.medias,
                        chatId: data.result.chat_id,
                    }, 
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async assignComplaint(complaintId: string, assignedId: number | null): Promise<IAppResponse<IUser>> {
        try {
            const res = await fetch(`${this._apiUsersPath}/${complaintId}/assign${ assignedId !== null ? `?assigned_id=${assignedId}` : "" }`, {
                method: "POST",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: {
                        id: data.id,
                        username: data.username,
                        email: data.email,
                        phone: data.phone,
                        fio: data.fio,
                        address: data.address,
                        role: data.role,
                    }, 
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async closeComplaint(complaintId: IComplaintFull["id"], status: ComplainFinishedStatus): Promise<IAppResponse<boolean>> {
        try {
            const res = await fetch(`${this._apiUsersPath}/${complaintId}/close?status=${status}`, {
                method: "POST",
                headers: this._defaultHeaders,
            });

            if (res.ok) {    
                return {
                    result: true,
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }
}

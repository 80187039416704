import React from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { INotificationsService, ServicesContext } from "../services";
import { INotification } from "../models";
import { SOMETHING_HAPPENED_WRONG_ERROR } from "../utils";
import { IconButton, Badge, CircularProgress, Menu, Typography, Stack } from "@mui/material";

import NotificationsIcon from "@mui/icons-material/NotificationsRounded";
import { InView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import moment, { duration } from "moment";

export const NotificationsButton = () => {
    const { notificationsService } = useContext(ServicesContext);
    const navigate = useNavigate();

    const [counter, setCounter] = useState<Awaited<ReturnType<INotificationsService["getNotificationsCount"]>> | null | undefined>();
    const [listOpened, setListOpened] = useState(false);

    const [relatedAnchor, setRelatedAnchor] = useState<null | HTMLElement>(null);

    const [notifications, setNotifications] = useState<INotification[] | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [notificationsLoading, setNotificationsLoading] = useState(false);

    const loadMore = useCallback(async () => {
        const take = 10;

        if (!notificationsLoading && hasMore) {
            setNotificationsLoading(true);

            try {
                const skip = notifications === null ? 0 : notifications.length;
                const next = await notificationsService.getNotifications(skip, take);

                if (next.result) {
                    setNotifications(
                        [
                            ...(notifications ?? []),
                            ...next.result,
                        ]
                    );

                    if (next.result.length < take) {
                        setHasMore(false);
                    }
                }
            } finally {
                setNotificationsLoading(false);
            }
        }
    }, [notifications, notificationsLoading, hasMore, setNotificationsLoading, setNotifications, setHasMore, notifications]);

    const openList = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setListOpened(true);
        setRelatedAnchor(e.currentTarget);

        if (!counter?.result !== undefined) {
            loadMore();
        }
    }, [counter, setCounter, setRelatedAnchor, notificationsService, setListOpened, loadMore]);

    const closeList = useCallback(() => {
        setListOpened(false);
        setRelatedAnchor(null);
    }, [setListOpened, setRelatedAnchor]);

    const loadNotificationsCount = useCallback(async () => {
        setCounter(null);
        try {
            const res = await notificationsService.getNotificationsCount();
            setCounter(res);
        } catch (e) {   
            console.error(e);
            setCounter({ error: SOMETHING_HAPPENED_WRONG_ERROR });
        }
    }, [notificationsService, setCounter]);

    const [lastViewed, setLastViewed] = useState<Date | null>(null);

    const viewNotification = useCallback(async (notification: INotification) => {
        if (lastViewed !== null && notification.sendAt < lastViewed) {
            notification.viewed = true;
            return;
        }

        try {
            const res = await notificationsService.readNotification(notification.id);
            if (res.result) {
                if (lastViewed === null || notification.sendAt > lastViewed) {
                    setLastViewed(notification.sendAt);
                    notification.viewed = false;
                    loadNotificationsCount();
                }
            }
        } catch (e) {
            console.error(e);
        }
    }, [lastViewed, setLastViewed, loadNotificationsCount, notificationsService]);

    const navigateTo = useCallback((ref: string) => {
        navigate(ref);
        closeList();
    }, [navigate, closeList]);

    const listener = useMemo(() => {
        const callback = (notification: INotification) => {
            if (counter?.result !== undefined) {
                setCounter({
                    result: counter.result + 1
                });
            }

            setNotifications([
                notification,
                ...(notifications ?? []),
            ]);
        };
        notificationsService.listen(callback);
        return callback;
    }, [notifications, counter, setCounter, setNotifications, notificationsService]);

    useEffect(() => {
        if (counter === undefined) {
            loadNotificationsCount();
        }

        return () => {
            notificationsService.unlisten(listener);
        };
    }, [counter, loadNotificationsCount, listener, notificationsService]);

    return (
        <>
            <IconButton
                size="large"
                color="inherit"
                onClick={openList}
            >
                <Badge badgeContent={counter?.result} color={counter?.result === undefined && counter?.error ? "error" : "default"}>
                    {
                        counter === undefined && counter === null ? (
                            <CircularProgress />
                        ) : <NotificationsIcon />
                    }
                </Badge>
            </IconButton>
            <Menu
                id="notifications-list"
                anchorEl={relatedAnchor}
                open={listOpened}
                onClose={closeList}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: "20vh",
                            width: "20rem",
                        },
                    }
                }}>
                {
                    notifications?.map((notification) => {
                        const content = (
                            <Stack flexWrap="wrap">
                                <Typography variant="body2">{notification.message}</Typography>
                                <Typography variant="caption" textAlign="right">{duration(moment().diff(notification.sendAt)).humanize()}</Typography>
                            </Stack>
                        );

                        return (
                            <Stack sx={{ px: 2 }} key={notification.id} onClick={ notification.link !== undefined ? () => navigateTo(notification.link!) : undefined}>
                                {
                                    !notification.viewed ? <InView onChange={(view) => {
                                        if (view) {
                                            viewNotification(notification);
                                        }
                                    }}>{content}</InView> : content
                                }
                            </Stack>
                        );
                    })
                }
                {
                    hasMore ? (
                        <InView onChange={(view) => {
                            if (view && !notificationsLoading) {
                                loadMore();
                            }
                        }}>
                            <Stack direction="column" alignItems="center">
                                <CircularProgress size={25} />
                            </Stack>
                        </InView>
                    ) : undefined
                }
            </Menu>
        </>
    );
};

import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/system";
import { AppBar, Box, Container, Stack, Toolbar, useTheme } from "@mui/material";
import { ThemeSelect } from "../../components/theme-select.component";
import { ReactComponent as TalismanIcon } from "../../assets/icons/talisman-with-text.svg";

export const Root = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.primary,
    height: "100%",
}));

export type IRootProps = {
    outlet?: React.ReactElement,
};

export const UnauthorizedRoot = (props: IRootProps) => {
    const theme = useTheme();

    return (
        <Root>
            <AppBar color="transparent" elevation={0} sx={{ pt: 2 }} position="sticky">
                <Toolbar>
                    <Box flexGrow={1} />
                    <Stack direction="row" spacing={2}>
                        <ThemeSelect />
                    </Stack>
                </Toolbar>
            </AppBar>
            <Container sx={{ flexBasis: "100%", overflowY: "auto" }}>
                <Container sx={{ textAlign: "center" }} maxWidth="tn">
                    <TalismanIcon fill={theme.palette.primary.main} height={theme.breakpoints.values.tn * 0.5} width={theme.breakpoints.values.tn * 0.5} />
                </Container>
                <Container maxWidth="sm" sx={{ px: 2, py: 5 }}>
                    {props.outlet ?? <Outlet />}
                </Container>
            </Container>
        </Root>
    );
};

import React, { useMemo } from "react";
import { Box, Divider, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { ComplaintStatus } from "../../models";
import { IComplaintReport } from "../../services";
import moment from "moment";
import { statusLabelFrom } from "../../components/complaint-status-chip.component";

export interface IReportsComplaintsPageProps {
    start: Date;
    end: Date;
    statusFilter: ComplaintStatus | null;
    reports: IComplaintReport[];
}

export const ReportsComplaintsPage = (props: IReportsComplaintsPageProps) => {
    const reportsFields = useMemo(() => {
        return Object.fromEntries(props.reports.map(report => {
            const complaintType = report.type;
            const fields = Object.entries(report.fields)
                .map(([id, value]: [string, any]) => [complaintType.fields.find(field => field.id === id)?.title ?? id, value])
                .filter(([, value]) => value !== undefined && value !== null && (typeof value !== "string" || value.trim().length !== 0));
            return [report.id, fields];
        }));
    }, [props.reports]);

    return (
        <>
            <Typography variant="h4">
                Отчет по обращениям {props.statusFilter !== null ? `со статусом "${statusLabelFrom(props.statusFilter)}"` : undefined}
            </Typography>
            <Typography variant="h6">За период {moment(props.start).format("DD.MM.YYYY")}-{moment(props.end).format("DD.MM.YYYY")}</Typography>

            <Divider sx={{ my: 2 }} orientation="horizontal" />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">ID Жалобы</TableCell>
                        <TableCell sx={{ maxWidth: 250 }} align="center">Информатор</TableCell>
                        <TableCell align="center">Данные</TableCell>
                        <TableCell sx={{ maxWidth: 200 }}>Адрес инцидента</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.reports.map((report, index) => (
                            <TableRow key={`report-row-${index}`}>
                                <TableCell>{report.id}</TableCell>
                                <TableCell align="center" sx={{ maxWidth: 250 }}>{report.user?.fio ?? report.user?.username ?? "-"}</TableCell>
                                {
                                    reportsFields[report.id].length !== 0 ? (
                                        <TableCell>
                                            <Stack direction="column">
                                                {reportsFields[report.id].map((field: any[], index: number) => (<Box key={`report-${report.id}-${index}`}>{field[0]}: {field[1]}</Box>))}
                                            </Stack>
                                        </TableCell>
                                    ) : <TableCell align="center">-</TableCell>
                                }
                                <TableCell sx={{ maxWidth: 200 }}>{report.address} <br /> ({report.latitude}, {report.longitude})</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </>
    );
};

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, Input, InputAdornment, IconButton, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, TextField, Tooltip, Typography } from "@mui/material";

import PlusIcon from "@mui/icons-material/AddRounded";
import MinusIcon from "@mui/icons-material/RemoveRounded";
import AddIcon from "@mui/icons-material/AddRounded";
import RemoveIcon from "@mui/icons-material/CloseRounded";
import SelectedIcon from "@mui/icons-material/CheckRounded";
import RetryIcon from "@mui/icons-material/ReplayRounded";
import AccountIcon from "@mui/icons-material/AccountCircleRounded";
import PasswordIcon from "@mui/icons-material/KeyRounded";
import VisibilityOnIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffRounded";
import EmailIcon from "@mui/icons-material/AlternateEmailRounded";
import FioIcon from "@mui/icons-material/BadgeRounded";
import PhoneIcon from "@mui/icons-material/PhoneRounded";
import AddressIcon from "@mui/icons-material/HouseRounded";
import EditIcon from "@mui/icons-material/EditRounded";

import { DialogTitle } from "@mui/material";
import { ICreateUserByAdminRequest, IUpdateUserByAdminRequest, IUsersService, ServicesContext } from "../../services";
import { AppError, groupBy, SOMETHING_HAPPENED_WRONG_ERROR, ValidationError } from "../../utils";
import { roleToLabel, UserRole } from "../../models";
import validator from "validator";
import { Controller, useForm } from "react-hook-form";

const accessGroupsTitle: Record<string, string> = {
    "users": "Пользователи",
    "checkpoints": "Объекты на карте",
    "address": "Адрес",
    "complaints_types": "Типы жалоб",
    "media": "Медиа",
    "complaints": "Жалобы"
};

const accessesGroupsKeys: Record<string, string> = {
    "users.get_me": "Данные профиля",
    "users.patch_me": "Изменение контактных данных аккаунта",
    "users.change_email": "Изменение адреса почты аккаунта",
    "users.change_password": "Изменение пароля аккаунта",
    "users.get": "Информации о пользователях",
    "users.create": "Добавление новых пользователей",
    "users.delete": "Удаление чужих аккаунтов",
    "users.change_access": "Изменение ролей и прав доступа",
    "checkpoints.list": "Доступ к списку объектов",
    "checkpoints.get": "Доступ к информации про объект",
    "address.geo": "Доступ к службе обратного геокодирования",
    "address.suggest": "Доступ к службе авто-заполнения адресов",
    "complaints_types.list": "Доступ к списку типов жалоб",
    "complaints_types.get": "Доступ к данным типа жалоб",
    "media.get": "Доступ к медиа",
    "media.post": "Отправка медиа",
    "complaints.my_list": "Доступ к списку моих жалоб",
    "complaints.list": "Доступ к списку всех жалоб",
    "complaints.get": "Получения данных жалобы",
    "complaints.post": "Отправка жалоб",
    "complaints.assign": "Назначение рассмотрение жалобы на себя",
    "complaints.change_status": "Изменение типа статуса",
};

export interface ISelectRoleAndAccessesProps {
    role: UserRole;
    setRole: (role: UserRole) => void;
    overrides: Record<string, boolean>;
    setOverrides: (overrides: Record<string, boolean>) => void;
    rolesDefaultAccesses: Record<UserRole, string[]>;
}

export const SelectRoleAndAccesses = (props: ISelectRoleAndAccessesProps) => {
    const roleDefaultAccesses = useMemo(() => props.rolesDefaultAccesses[props.role], [props.role, props.rolesDefaultAccesses]);

    const accessesByGroups = useMemo(() => {
        const uniqueAccesses = Array.from(new Set(Array.from(Object.values(props.rolesDefaultAccesses)).flatMap(id => id)));
        return Array.from(groupBy(uniqueAccesses, (access) => access.split(".")[0]));
    }, [props.rolesDefaultAccesses]);

    const roleChanged = useCallback((event: SelectChangeEvent<UserRole>) => {
        props.setRole(event.target.value as UserRole);
    }, [props.setRole]);

    const toggleGroup = useCallback((e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        const group = e.currentTarget.parentElement?.dataset.group;

        if (group === undefined) {
            return;
        }

        const groupAccesses = accessesByGroups.find((([groupTitle]) => groupTitle === group));

        if (groupAccesses === undefined) {
            return;
        }

        props.setOverrides({
            ...props.overrides,
            ...Object.fromEntries(groupAccesses[1].map(access => [access, value]))
        });
    }, [props.role, props.rolesDefaultAccesses, props.setOverrides, props.overrides, accessesByGroups, roleDefaultAccesses]);

    const toggleAccess = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const access = e.currentTarget.dataset.access;

        if (access === undefined) {
            return;
        }

        if (props.overrides[access] !== undefined) {
            props.setOverrides({
                ...props.overrides,
                [access]: !props.overrides[access],
            });
        } else {
            const isDefaultAccess = roleDefaultAccesses.includes(access);

            props.setOverrides({
                ...props.overrides,
                [access]: !isDefaultAccess,
            });
        }
    }, [props.overrides, props.setOverrides, props.role, roleDefaultAccesses]);

    const resetAccess = useCallback((e: React.SyntheticEvent<HTMLElement>) => {
        const access = e.currentTarget.parentElement?.dataset.access;

        if (access === undefined) {
            return;
        }

        const copy = {...props.overrides};
        delete copy[access];
        props.setOverrides(copy);
    }, [props.overrides, props.setOverrides]);

    const resetAllOverrides = useCallback(() => {
        props.setOverrides({});
    }, [props.setOverrides]);


    return (<>
        <Stack direction="row" alignItems="center" useFlexGap spacing={1.5} flexWrap="wrap">
            <Typography variant="subtitle1"><b>Роль пользователя:</b></Typography>
            <Select
                labelId="role-label"
                label="Роль пользователя"
                id="role-select"
                value={props.role}
                onChange={roleChanged}
                input={<Input disableUnderline id="select-multiple-chip" />}
                renderValue={(selected) => roleToLabel(selected)}
            >
                {[UserRole.user, UserRole.manager, UserRole.admin].map((status) => (
                    <MenuItem
                        key={status}
                        value={status}
                        selected={props.role === status}
                    >
                        {props.role === status ? <SelectedIcon sx={{ mr: 1 }} /> : undefined}
                        {roleToLabel(status)}
                    </MenuItem>
                ))}
            </Select>
            {
                Object.keys(props.overrides).length !== 0 ? (
                    <Button size="small" variant="contained" color="secondary" onClick={resetAllOverrides}>По умолчанию</Button>
                ) : undefined
            }
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Typography variant="subtitle1"><b>Доступ к функционалу:</b></Typography>
        {
            accessesByGroups.map(([group, keys]) => {
                const title = accessGroupsTitle[group];

                const groupChecked = keys.every(access => props.overrides[access] !== undefined ? props.overrides[access] : roleDefaultAccesses.includes(access));
                const groupIndeterminate = !groupChecked && keys.some(access => props.overrides[access] !== undefined ? props.overrides[access] : roleDefaultAccesses.includes(access));

                return (
                    <>
                        <FormControlLabel
                            label={title ?? group}
                            control={
                                <Checkbox
                                    data-group={group}
                                    checked={groupChecked}
                                    indeterminate={groupIndeterminate}
                                    onChange={toggleGroup}
                                />
                            }
                        />
                        <Stack direction="row" flexWrap="wrap" useFlexGap spacing={1}>
                            {keys.map((access) => {
                                const defaultForRole = roleDefaultAccesses.includes(access);
                                const hasAccess = props.overrides[access] ?? defaultForRole;
                                const overrides = props.overrides[access] !== undefined;

                                return (<Chip
                                    data-access={access}
                                    color={hasAccess ? "success" : "error"}
                                    variant={overrides ? "filled" : "outlined"}
                                    key={access}
                                    label={accessesGroupsKeys[access] ?? access}
                                    clickable
                                    onClick={toggleAccess}
                                    onDelete={overrides ? resetAccess : undefined}
                                    icon={
                                        hasAccess ? <PlusIcon /> : <MinusIcon />
                                    }
                                    deleteIcon={
                                        overrides ? (
                                            <Tooltip title="Сделать по умолчанию">
                                                <RemoveIcon />
                                            </Tooltip>
                                        ) : undefined
                                    }
                                />);
                            })}
                        </Stack>
                    </>
                );
            })
        }
    </>);
};

const validateUsername = (data: ICreateUserByAdminRequest["username"]) => (/^[a-zA-Z0-9_]+$/).test(data) || "Допустимы только латиницу, цифры и _";
const validatePassword = (data: ICreateUserByAdminRequest["password"]) => (/^[a-zA-Z0-9_]+$/).test(data) || "Допустимы только латиница, цифры и _";
const validateUpdatePassword = (data: ICreateUserByAdminRequest["password"]) => (/^[a-zA-Z0-9_]*$/).test(data) || "Допустимы только латиница, цифры и _";
const validateEmail = (data: ICreateUserByAdminRequest["email"]) => validator.isEmail(data) || "Неправильный email";
const validatePhone = (data: ICreateUserByAdminRequest["phone"]) => (/^(\+7|7|8)?[\s\\-]?\(?[489][0-9]{2}\)?[\s\\-]?[0-9]{3}[\s\\-]?[0-9]{2}[\s\\-]?[0-9]{2}$/).test(data) ||  "Некорректный номер";

const usernameRules = {
    required: "Поле обязательно",
    minLength: {
        value: 3,
        message: "Минимум 3 символа"
    },
    maxLength: {
        value: 150,
        message: "Максимум 150 символов"
    },
    validate: validateUsername,
};

const passwordRules = {
    required: "Поле обязательно",
    minLength: {
        value: 3,
        message: "Минимум 3 символа"
    },
    maxLength: {
        value: 150,
        message: "Максимум 150 символов"
    },
    validate: validatePassword,
};

const updatePasswordRules = {
    minLength: {
        value: 3,
        message: "Минимум 3 символа"
    },
    maxLength: {
        value: 150,
        message: "Максимум 150 символов"
    },
    validate: validateUpdatePassword,
};

const emailRules = {
    required: "Поле обязательно",
    minLength: {
        value: 3,
        message: "Минимум 3 символа"
    },
    maxLength: {
        value: 150,
        message: "Максимум 150 символов"
    },
    validate: validateEmail,
};

const phoneRules = {
    required: "Поле обязательно",
    validate: validatePhone,
};

const fioRules = {
    required: "Поле обязательно",
    minLength: {
        value: 2,
        message: "Минимум 2 символа"
    },
    maxLength: {
        value: 200,
        message: "Максимум 200 символов"
    },
};

const addressRules = {
    required: "Поле обязательно",
    minLength: {
        value: 2,
        message: "Минимум 2 символа"
    },
    maxLength: {
        value: 250,
        message: "Максимум 250 символов"
    },
};

const defaultCreateUserValues: () => ICreateUserByAdminRequest = () => ({
    username: "",
    password: "",
    email: "",
    phone: "",
    fio: "",
    address: "",
    role: UserRole.manager,
    accessOverrides: {},
});

const defaultUpdateUserValues: () => IUpdateUserByAdminRequest = () => ({
    username: "",
    password: "",
    email: "",
    phone: "",
    fio: "",
    address: "",
    role: UserRole.manager,
    accessesOverrides: {},
});

export interface ICreateUserButtonProps {
    onCreated: () => void;
}

export const CreateUserButton = (props: ICreateUserButtonProps) => {
    const [isModalOpened, setModalOpened] = useState(false);

    const { usersService } = useContext(ServicesContext);

    const [, forceSet] = useState({});
    const [rolesAccesses, setRolesAccesses] = useState<Awaited<ReturnType<IUsersService["getRolesAccesses"]>> | null | undefined>();

    const [loading, setLoading] = useState(false);
    const [obscurePassword, setObscurePassword] = useState(true);

    const {
        control,
        handleSubmit,
        setError,
        setValue,
        getValues,
        clearErrors,
        reset,
        formState: {
            errors,
            isValid,
        }
    } = useForm<ICreateUserByAdminRequest>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultCreateUserValues(),
    });

    const loadRolesAccesses = useCallback(async () => {
        setRolesAccesses(null);
        try {
            const result = await usersService.getRolesAccesses();
            setRolesAccesses(result);
        } catch {
            setRolesAccesses({
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            });
        }
    }, [rolesAccesses, setRolesAccesses, usersService]);

    const openModal = useCallback(() => {
        setModalOpened(true);
    }, [setModalOpened]);

    const closeModal = useCallback(() => {
        clearErrors();
        reset(defaultCreateUserValues());
        setModalOpened(false);
    }, [setModalOpened]);

    const toggleObscure = useCallback(() => {
        setObscurePassword(!obscurePassword);
    }, [obscurePassword, setObscurePassword]);

    useEffect(() => {
        if (rolesAccesses === undefined) {
            loadRolesAccesses();
        }
    }, [rolesAccesses, loadRolesAccesses]);

    const onSubmit = useMemo(() => handleSubmit(async (data) => {
        setLoading(true);
        clearErrors();
        try {
            const res = await usersService.createUserByAdmin(data);

            if (res.result === undefined) {
                if (res.error.mainMessage !== undefined) {
                    setError("root.server", { message: res.error.mainMessage });
                }
                if (res.error instanceof ValidationError) {
                    for (const key in res.error.errors) {
                        setError(key as keyof ICreateUserByAdminRequest, { message: res.error.errors[key as keyof ICreateUserByAdminRequest] });
                    }
                }
            } else {
                closeModal();
                props.onCreated();
            }
        } catch (e) {
            console.error(e);
            setError("root.server", { message: (e as Error).toString() });
        } finally {
            setLoading(false);
        }
    }), [props.onCreated, handleSubmit, closeModal]);

    const {
        role,
        accessOverrides,
    } = getValues();

    return <>
        <Button onClick={openModal} startIcon={(<AddIcon />)} color="primary" variant="contained">
            Создать
        </Button>
        <Dialog maxWidth="lg" open={isModalOpened} onClose={closeModal}>
            <DialogTitle>Создать нового пользователя</DialogTitle>
            <DialogContent dividers>
                <Stack direction="row" maxHeight="60vh" spacing={3}>
                    <Stack direction="column" minWidth="400px" spacing={2} overflow="auto" sx={{ py: 1 }}>
                        <Controller
                            control={control}
                            rules={usernameRules}
                            disabled={loading}
                            name="username"
                            render={({ field, fieldState }) => <TextField
                                id="login"
                                {...field}
                                label="Логин"
                                error={fieldState.error !== undefined}
                                helperText={fieldState.error?.message}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />}
                        />
                        <Controller
                            control={control}
                            rules={passwordRules}
                            disabled={loading}
                            name="password"
                            render={({ field, fieldState }) => <TextField
                                id="password"
                                type={ obscurePassword ? "password" : "text" }
                                {...field}
                                label="Пароль"
                                error={fieldState.error !== undefined}
                                helperText={fieldState.error?.message}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={toggleObscure}>
                                                { obscurePassword ? <VisibilityOffIcon /> : <VisibilityOnIcon /> }
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        />
                        <Controller
                            control={control}
                            rules={emailRules}
                            disabled={loading}
                            name="email"
                            render={({ field, fieldState }) => <TextField
                                id="email"
                                type="email"
                                {...field}
                                label="Эл. почта"
                                error={fieldState.error !== undefined}
                                helperText={fieldState.error?.message}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        />
                        <Controller
                            control={control}
                            rules={fioRules}
                            disabled={loading}
                            name="fio"
                            render={({ field, fieldState }) => <TextField
                                id="fio"
                                type="text"
                                {...field}
                                label="Ф.И.О."
                                error={fieldState.error !== undefined}
                                helperText={fieldState.error?.message}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FioIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        />
                        <Controller
                            control={control}
                            rules={phoneRules}
                            disabled={loading}
                            name="phone"
                            render={({ field, fieldState }) => <TextField
                                id="phone"
                                type="phone"
                                {...field}
                                label="Номер телефона"
                                error={fieldState.error !== undefined}
                                helperText={fieldState.error?.message}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        />
                        <Controller
                            control={control}
                            rules={addressRules}
                            disabled={loading}
                            name="address"
                            render={({ field, fieldState }) => <TextField
                                id="address"
                                type="text"
                                {...field}
                                label="Адрес"
                                error={fieldState.error !== undefined}
                                helperText={fieldState.error?.message}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AddressIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        />
                        {
                            errors?.root?.server !== undefined
                                ? (<Typography align="right" variant="caption" color="error">{errors?.root.server.message}</Typography>)
                                : undefined
                        }
                    </Stack>
                    <Stack direction="column" overflow="auto">
                        {
                            rolesAccesses?.result ? (
                                <SelectRoleAndAccesses
                                    role={role} setRole={(role) => {
                                        setValue("role", role);
                                        forceSet({});
                                    }}
                                    overrides={accessOverrides} setOverrides={(overrides) => {
                                        setValue("accessOverrides", overrides);
                                        forceSet({});
                                    }}
                                    rolesDefaultAccesses={rolesAccesses.result} />
                            ) : rolesAccesses ? (
                                <Chip icon={<RetryIcon />} onClick={loadRolesAccesses} label="Произошла ошибка"/>
                            ) : (
                                <CircularProgress />
                            )
                        }
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button startIcon={loading! ? <CircularProgress size={16} /> : undefined} disabled={loading || !isValid} onClick={onSubmit} variant="contained" color="secondary" size="medium">
                    Создать
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};

export interface IUpdateUserButtonProps {
    userId: number;
    onUpdated: () => void;
    onCancel: () => void;
}

export const UpdateUserButton = (props: IUpdateUserButtonProps) => {
    const { usersService } = useContext(ServicesContext);

    const [isOpened, setIsOpened] = useState(false);

    const [, forceSet] = useState({});
    const [originUser, setOriginUser] = useState<IUpdateUserByAdminRequest | null>(null);
    const [rolesAccesses, setRolesAccesses] = useState<Awaited<ReturnType<IUsersService["getRolesAccesses"]>> | null | undefined>();

    const [loading, setLoading] = useState(false);
    const [obscurePassword, setObscurePassword] = useState(true);

    const {
        control,
        handleSubmit,
        setError,
        setValue,
        getValues,
        clearErrors,
        reset,
        formState: {
            errors,
            isValid,
        }
    } = useForm<Required<IUpdateUserByAdminRequest>>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultUpdateUserValues(),
    });

    const loadData = useCallback(async () => {
        setRolesAccesses(null);
        setLoading(true);
        try {
            const rolesAccesses = await usersService.getRolesAccesses();

            setRolesAccesses(rolesAccesses);

            const user = await usersService.getUser(props.userId);

            if (user.result === undefined) {
                throw user.error;
            }

            if (rolesAccesses.result === undefined) {
                throw rolesAccesses.error;
            }

            if (user.result === null) {
                setRolesAccesses({
                    error: new AppError("Пользователь не найден"),
                });
                return;
            }

            const userRoleAccesses = rolesAccesses.result![user.result?.role];

            const userAccesses = user.result.accesses;

            const hasAccesses = userAccesses.filter(access => !userRoleAccesses.includes(access));
            const hasNotAccesses = userRoleAccesses.filter(access => !userAccesses.includes(access));

            const accessesOverrides = Object.fromEntries([
                ...hasAccesses.map(e => [e, true]),
                ...hasNotAccesses.map(e => [e, false]),
            ]);

            setValue("username", user.result.username);
            setValue("email", user.result.email ?? "");
            setValue("phone", user.result.phone ?? "");
            setValue("address", user.result.address ?? "");
            setValue("role", user.result.role);
            setValue("accessesOverrides", accessesOverrides);
            setValue("fio", user.result.fio ?? "");

            setOriginUser({
                ...user.result,
                password: "",
            });
        } catch {
            setRolesAccesses({
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            });
        } finally {
            setLoading(false);
        }
    }, [props.userId, rolesAccesses, usersService]);

    const openModal = useCallback(() => {
        setIsOpened(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsOpened(false);
        setOriginUser(null);
        clearErrors();
        reset(defaultUpdateUserValues());
    }, []);

    const cancel = useCallback(() => {
        closeModal();
        props.onCancel();
    }, [closeModal, props.onCancel]);

    const toggleObscure = useCallback(() => {
        setObscurePassword(!obscurePassword);
    }, [obscurePassword]);

    useEffect(() => {
        if (isOpened && !loading && originUser === null) {
            loadData();
        }
    }, [props.userId, isOpened, loading, originUser, loadData]);

    const onSubmit = useMemo(() => handleSubmit(async (data) => {
        setLoading(true);
        clearErrors();
        try {
            const updateData: IUpdateUserByAdminRequest = {};

            if (originUser !== null) {
                for (const key in data) {
                    if (originUser[key as keyof Required<IUpdateUserByAdminRequest>] !== data[key as keyof Required<IUpdateUserByAdminRequest>]) {                        
                        (updateData as any)[key] = data[key as keyof Required<IUpdateUserByAdminRequest>]!;
                    }
                }
            }

            const res = await usersService.updateUserByAdmin(props.userId, updateData);

            if (res.result === undefined) {
                if (res.error.mainMessage !== undefined) {
                    setError("root.server", { message: res.error.mainMessage });
                }
                if (res.error instanceof ValidationError) {
                    for (const key in res.error.errors) {
                        setError(key as keyof IUpdateUserByAdminRequest, { message: res.error.errors[key as keyof IUpdateUserByAdminRequest] });
                    }
                }
            } else {
                closeModal();
                props.onUpdated();
            }
        } catch (e) {
            console.error(e);
            setError("root.server", { message: (e as Error).toString() });
        } finally {
            setLoading(false);
        }
    }), [props, originUser, handleSubmit, closeModal]);

    const {
        role,
        accessesOverrides,
    } = getValues();

    return <>
        <MenuItem
            onClick={openModal}
            disabled={loading}
        >
            <ListItemIcon>
                <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Изменить роль и права</ListItemText>
        </MenuItem>
        {
            isOpened ? (
                <Dialog maxWidth="lg" open={isOpened} onClose={cancel}>
                    <DialogTitle>Обновить пользователя</DialogTitle>
                    <DialogContent dividers>
                        <Stack direction="row" maxHeight="60vh" spacing={3}>
                            <Stack direction="column" minWidth="400px" spacing={2} overflow="auto" sx={{ py: 1 }}>
                                <Controller
                                    control={control}
                                    rules={usernameRules}
                                    disabled={loading}
                                    name="username"
                                    render={({ field, fieldState }) => <TextField
                                        id="login"
                                        {...field}
                                        label="Логин"
                                        error={fieldState.error !== undefined}
                                        helperText={fieldState.error?.message}
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />}
                                />
                                <Controller
                                    control={control}
                                    rules={updatePasswordRules}
                                    disabled={loading}
                                    name="password"
                                    render={({ field, fieldState }) => <TextField
                                        id="password"
                                        type={ obscurePassword ? "password" : "text" }
                                        {...field}
                                        label="Пароль"
                                        error={fieldState.error !== undefined}
                                        helperText={fieldState.error?.message}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PasswordIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={toggleObscure}>
                                                        { obscurePassword ? <VisibilityOffIcon /> : <VisibilityOnIcon /> }
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                />
                                <Controller
                                    control={control}
                                    rules={emailRules}
                                    disabled={loading}
                                    name="email"
                                    render={({ field, fieldState }) => <TextField
                                        id="email"
                                        type="email"
                                        {...field}
                                        label="Эл. почта"
                                        error={fieldState.error !== undefined}
                                        helperText={fieldState.error?.message}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                />
                                <Controller
                                    control={control}
                                    rules={fioRules}
                                    disabled={loading}
                                    name="fio"
                                    render={({ field, fieldState }) => <TextField
                                        id="fio"
                                        type="text"
                                        {...field}
                                        label="Ф.И.О."
                                        error={fieldState.error !== undefined}
                                        helperText={fieldState.error?.message}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FioIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                />
                                <Controller
                                    control={control}
                                    rules={phoneRules}
                                    disabled={loading}
                                    name="phone"
                                    render={({ field, fieldState }) => <TextField
                                        id="phone"
                                        type="phone"
                                        {...field}
                                        label="Номер телефона"
                                        error={fieldState.error !== undefined}
                                        helperText={fieldState.error?.message}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                />
                                <Controller
                                    control={control}
                                    rules={addressRules}
                                    disabled={loading}
                                    name="address"
                                    render={({ field, fieldState }) => <TextField
                                        id="address"
                                        type="text"
                                        {...field}
                                        label="Адрес"
                                        error={fieldState.error !== undefined}
                                        helperText={fieldState.error?.message}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AddressIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                />
                                {
                                    errors?.root?.server !== undefined
                                        ? (<Typography align="right" variant="caption" color="error">{errors?.root.server.message}</Typography>)
                                        : undefined
                                }
                            </Stack>
                            <Stack direction="column" overflow="auto">
                                {
                                    rolesAccesses?.result ? (
                                        <SelectRoleAndAccesses
                                            role={role} setRole={(role) => {
                                                setValue("role", role);
                                                forceSet({});
                                            }}
                                            overrides={accessesOverrides} setOverrides={(overrides) => {
                                                setValue("accessesOverrides", overrides);
                                                forceSet({});
                                            }}
                                            rolesDefaultAccesses={rolesAccesses.result} />
                                    ) : rolesAccesses ? (
                                        <Chip icon={<RetryIcon />} onClick={loadData} label="Произошла ошибка"/>
                                    ) : (
                                        <CircularProgress />
                                    )
                                }
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={cancel} variant="text" color="secondary" size="medium">
                        Отмена
                        </Button>
                        <Button startIcon={loading! ? <CircularProgress size={16} /> : undefined} disabled={loading || !isValid} onClick={onSubmit} variant="contained" color="secondary" size="medium">
                        Обновить
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : undefined
        }
    </>;
};

import { IUser } from "./user";

export interface IChat {
    id: string;
    members: IUser["id"][];
    lastViewed?: string;
    unread: number;
    closed: boolean;
}

export enum ChatRecordType {
    message = "chat_record_message",
    action = "chat_record_action",
}

export interface IChatRecordBase {
    id: string;
    chatId: IChat["id"];
    userId: IUser["id"];
    sendAt: Date;
    type: ChatRecordType;
    viewed: boolean;
}

export interface IChatMessageRecord extends IChatRecordBase {
    type: ChatRecordType.message;
    message: string;
    medias: Record<string, string | undefined>;
}

export interface IChatActionRecord extends IChatRecordBase {
    type: ChatRecordType.action;
    actionType: string;
}

export type IChatRecord =
    IChatMessageRecord |
    IChatActionRecord;

import CancellationToken from "cancellationtoken";
import { ComplaintStatus, IComplaintFull, IComplaintShort, IComplaintType, ISessionToken, IUser } from "../models";
import { ComplaintsOrderStrategy, IComplaintsService, IComplaintStatusChanges } from "./complaints.service";
import { IUsersService } from "./users.service";

export interface IComplaintReport {
    id: string;
    type: IComplaintType;
    user?: IUser;
    created: Date;
    fields: Record<string, any>;
    address: string;
    latitude: number;
    longitude: number;
}

export interface IComplaintChangeReport {
    complaint: IComplaintFull;
    change: IComplaintStatusChanges;
}

export interface IReportsService {
    byDate(start: Date, end: Date, status?: ComplaintStatus, token?: CancellationToken): Promise<IComplaintReport[]>;
    byStatusChanges(start: Date, end: Date, token?: CancellationToken): Promise<IComplaintChangeReport[]>;
}

export class ReportsService implements IReportsService {
    private readonly _apiUsersPath;
    private readonly _complaintsService: IComplaintsService;
    private readonly _usersService: IUsersService;

    private readonly _defaultHeaders: HeadersInit;

    private readonly _token: ISessionToken | undefined;

    constructor(token: ISessionToken | undefined, complaintsService: IComplaintsService, usersService: IUsersService) {
        this._apiUsersPath = `${process.env.REACT_APP_API_PATH}/complaints`;
        this._defaultHeaders = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        };
        this._complaintsService = complaintsService;
        this._usersService = usersService;
        this._token = token;
        if (this._token !== undefined) {
            this._defaultHeaders["Authorization"] = `${this._token.tokenType} ${this._token.token}`;
        }
    }

    async byDate(start: Date, end: Date, status?: ComplaintStatus, token?: CancellationToken): Promise<IComplaintReport[]> {
        const uniqueComplaints: Map<IComplaintShort["id"], IComplaintFull> = new Map();

        let total = 0;

        do {
            token?.throwIfCancelled();

            const result = await this._complaintsService.getComplaints({
                skip: uniqueComplaints.size,
                orderBy: ComplaintsOrderStrategy.byCreated,
                reverse: true,
                take: 50,
                statusFilter: status,
                start,
                end,
            });

            if (result.result) {
                const prevSize = uniqueComplaints.size;
                total = result.result.total;

                if (total === 0) {
                    break;
                }

                token?.throwIfCancelled();

                const complaintsFull = await this._complaintsService.getComplaintsByIds(result.result.complaints.map(complaint => complaint.id));

                if (!complaintsFull.result) {
                    throw complaintsFull.error;
                }

                for (const complaint of complaintsFull.result) {
                    uniqueComplaints.set(complaint.id, complaint);
                }
                if (result.result.complaints.length === 0 || uniqueComplaints.size === prevSize) {
                    break;
                }
            } else {
                throw result.error;
            }
        } while(uniqueComplaints.size < total);

        token?.throwIfCancelled();

        const types = await this._complaintsService.getComplaintTypes();
        
        if (!types.result) {
            throw types.error;
        }

        const complaints = Array.from(uniqueComplaints.values());

        const userIds = Array.from(new Set(complaints.map(complaint => complaint.userId)));

        token?.throwIfCancelled();

        const users = await Promise.all(userIds.map(userId => this._usersService.getUser(userId)));

        for (const user of users) {
            if (user.result === undefined) {
                throw user.error;
            }
        }

        const typesMapped = new Map(types.result.flatMap(group => group.types).map(type_ => [type_.id, type_]));
        const usersMapped = new Map(users.map(user => [user.result!.id, user.result!]));

        const reports: IComplaintReport[] = complaints.map(complaint => ({
            id: complaint.id,
            type: typesMapped.get(complaint.complaintTypeId)!,
            user: usersMapped.get(complaint.userId),
            fields: complaint.fields,
            address: complaint.address,
            latitude: complaint.lat,
            longitude: complaint.lon,
            created: complaint.createdAt,
        }));

        reports.sort((a, b) => +a.created - +b.created);

        return reports;
    }

    async byStatusChanges(start: Date, end: Date, token?: CancellationToken): Promise<IComplaintChangeReport[]> {
        const uniqueChanges: Map<IComplaintStatusChanges["id"], IComplaintStatusChanges> = new Map();

        while(true) {
            token?.throwIfCancelled();

            const result = await this._complaintsService.getComplaintsChanges({
                skip: uniqueChanges.size,
                take: 50,
                start,
                end,
            });

            if (result.result) {
                const prevSize = uniqueChanges.size;

                token?.throwIfCancelled();

                for (const change of result.result.changes) {
                    uniqueChanges.set(change.id, change);
                }

                if (result.result.changes.length === 0 || uniqueChanges.size === prevSize) {
                    break;
                }
            } else {
                throw result.error;
            }
        }

        const complaintIds = Array.from(new Set(Array.from(uniqueChanges.values()).map(change => change.complaintId)));

        const uniqueComplaints: Map<IComplaintShort["id"], IComplaintFull> = new Map();

        for (let i = 0; i < complaintIds.length; i += 50) {
            const prevSize = uniqueComplaints.size;

            token?.throwIfCancelled();

            const complaintsFull = await this._complaintsService.getComplaintsByIds(complaintIds.slice(i, i + 50));

            if (!complaintsFull.result) {
                throw complaintsFull.error;
            }

            for (const complaint of complaintsFull.result) {
                uniqueComplaints.set(complaint.id, complaint);
            }
            if (complaintsFull.result.length === 0 || uniqueComplaints.size === prevSize) {
                break;
            }
        }
        
        const changes = Array.from(uniqueChanges.values());

        changes.sort((a, b) => +a.dt - +b.dt);

        return changes.map(change => ({
            change,
            complaint: uniqueComplaints.get(change.complaintId)!,
        }));
    }
}

export class AppError extends Error {
    public readonly mainMessage: string | undefined;

    constructor(message?: string) {
        super(message);
        this.mainMessage = message;
    }
}

export const SOMETHING_HAPPENED_WRONG_ERROR: AppError = new AppError("Что-то пошло не так, попробуйте позже");
export const NOT_FOUND: AppError = new AppError("Не найдено");
export const NOT_CONNECTION: AppError = new AppError("Нет связи");
export const CANCELED: AppError = new AppError("Отменено");

type IValidationEntries<T> = Partial<{
    [K in keyof T]: string
}>;

export class ValidationError<T> extends AppError {
    public readonly errors: IValidationEntries<T>;

    constructor(mainMessage: string | undefined, errors: IValidationEntries<T>) {
        super(mainMessage);
        this.errors = errors;
    }
}

export type IAppResponse<T, V = never> = {
    result: T,
} | {
    result?: undefined,
    error: AppError | ValidationError<V>,
};

const EXCEPTION_CODES_LABELS: Record<string, string> = {
    "ERROR_COMPLAINT_NOT_FOUND": "Жалоба не найдена",
    "ERROR_COMPLAINT_NOT_ASSIGNED_TO_USER": "Жалоба рассматривается не Вами",
    "ERROR_COMPLAINT_NOT_PROCESSING": "Жалоба не в состоянии обработки",
    "ERROR_COMPLAINT_REQUIRES_CHAT_ANSWER": "Необходим ответ",
};

export async function parseErrors<V = never> (body: Response): Promise<AppError | ValidationError<V>> {
    if (body.status === 400 || body.status === 422) {
        const json = await body.json();

        const main = EXCEPTION_CODES_LABELS[json.detail] ?? json.detail;

        const validation = {} as IValidationEntries<V>;

        if (json.errors !== undefined) {
            for (const error of Object.values(json.errors)) {
                const {
                    loc,
                    msg
                } = error as {
                    loc: string[],
                    msg: string
                };
    
                const removedPrefixLoc = loc.at(-1) as keyof V;
    
                validation[removedPrefixLoc] = msg;
            }
    
        }

        return new ValidationError(main, validation);
    } else if (body.status === 404 || body.status === 405) {
        return new AppError("Не найдено");
    } else if (body.status === 401 || body.status == 403) {
        return new AppError("Нет доступа");
    }
    return new AppError("Ошибка сервера, повторите попытку позже");
}

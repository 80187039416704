import React, { useEffect } from "react";
import { ThemeProvider } from "@emotion/react";
import { createAppTheme } from "./styles";
import { useAppSelector, useAppStore } from "./hooks";
import { AppRouter } from "./pages/router/app-router";
import { ChatsService, ComplaintsService, IServices, ServicesContext, UsersService, MediasService, NotificationsService, ReportsService,  } from "./services";
import { CssBaseline } from "@mui/material";
import { FullscreenMedia } from "./components/media.component";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "moment/locale/ru";

function App() {
    const store = useAppStore();
    useAppSelector(state => state.theme);
    useAppSelector(state => state.session);

    const {
        session,
        theme
    } = store.getState();

    const usersService = new UsersService(session.token);
    const complaintsService = new ComplaintsService(session.token);
    const chatsService = new ChatsService(session.token);
    const mediasService = new MediasService(session.token);
    const notificationsService = new NotificationsService(session.token);
    const reportsService = new ReportsService(session.token, complaintsService, usersService);

    const services: IServices = {
        usersService,
        complaintsService,
        chatsService,
        mediasService,
        notificationsService,
        reportsService,
    };

    useEffect(() => {
        return () => {
            chatsService.close();
        };
    });

    return (
        <ThemeProvider theme={createAppTheme(theme.theme)}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
                <ServicesContext.Provider value={services}>
                    <AppRouter />
                    <FullscreenMedia />
                </ServicesContext.Provider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;

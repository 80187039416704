
import { createContext } from "react";
import { IComplaintsService } from "./complaints.service";
import { IUsersService } from "./users.service";
import { IChatService } from "./chats.service";
import { IMediasService } from "./medias.service";
import { INotificationsService } from "./notifications.service";
import { IReportsService } from "./reports.service";

export * from "./users.service";
export * from "./complaints.service";
export * from "./chats.service";
export * from "./medias.service";
export * from "./notifications.service";
export * from "./reports.service";

export interface IServices {
    usersService: IUsersService,
    complaintsService: IComplaintsService,
    chatsService: IChatService,
    mediasService: IMediasService,
    notificationsService: INotificationsService,
    reportsService: IReportsService;
}

export const ServicesContext = createContext<IServices>({} as unknown as IServices);

export enum UserRole {
    user = 1,
    manager = 2,
    admin = 3,
}

export function roleToLabel(userRole: UserRole) {
    switch (userRole) {
    case UserRole.user: {
        return "Пользователь";
    }
    case UserRole.manager: {
        return "Оператор";
    }
    case UserRole.admin: {
        return "Администратор";
    }
    }
}

export interface IUser {
    id: number;
    username: string;
    email?: string;
    phone?: string;
    fio?: string;
    address?: string;
    role: UserRole;
}

export interface IUserFull extends IUser {
    accesses: string[];
}

export type ISessionToken = {
    token: string,
    tokenType: string,
};

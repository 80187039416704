import React from "react";
import { IComplaintCardProps } from "./props";
import { LoadedComplaintCardContent } from "./loaded/loaded-card.component";
import { Card, CircularProgress } from "@mui/material";

export const ComplaintCard = (props: IComplaintCardProps) => {
    return (
        <Card elevation={10} sx={{ py: 2, px: 4, display: "flex", flexDirection: "column", flexBasis: "100%", overflow: "auto" }}>
            {
                props.complaint !== null && props.complaint.result !== undefined ? (
                    <LoadedComplaintCardContent
                        {...props}
                        complaint={props.complaint.result.complaint}
                        type={props.complaint.result.type} />
                ) : props.complaint === null ? (
                    <CircularProgress />
                ) : (
                    <div>Error</div>
                )
            }
        </Card>
    );
};

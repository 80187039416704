import { ISessionToken } from "../models";
import { IAppResponse, parseErrors, SOMETHING_HAPPENED_WRONG_ERROR } from "../utils";

export interface IMediasService {
    getMedia(mediaPath: string): Promise<IAppResponse<string | null>>;
}

export class MediasService implements IMediasService {
    private readonly _apiMediasPath;

    private readonly _defaultHeaders: HeadersInit;

    private readonly _mediasUrls: Record<string, string> = {};

    constructor(token: ISessionToken | undefined) {
        this._apiMediasPath = `${process.env.REACT_APP_API_PATH}/medias`;
        this._defaultHeaders = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        };
        if (token !== undefined) {
            this._defaultHeaders["Authorization"] = `${token.tokenType} ${token.token}`;
        }
    }

    async getMedia(mediaPath: string): Promise<IAppResponse<string | null>> {
        if (this._mediasUrls[mediaPath] !== undefined) {
            return { result: this._mediasUrls[mediaPath], };
        }
        
        try {
            const res = await fetch(`${this._apiMediasPath}/${mediaPath}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.status === 404) {
                return {
                    result: null,
                };
            }

            if (res.ok) {
                const blob = await res.blob();

                const url = URL.createObjectURL(blob);
    
                this._mediasUrls[mediaPath] = url;

                return {
                    result: url,
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }
}

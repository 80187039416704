import React from "react";
import { DateTimeRangePicker } from "../../components/date-time-range-picker";
import { Range } from "react-date-range";
import { Stack } from "@mui/material";

export interface IReportsComplaintsStatusChangesTabProps {
    reportsRange: Range;
    setReportsRange: (range: Range) => void;

    disabled: boolean;
}

export const ReportsComplaintsStatusChangesTab = (props: IReportsComplaintsStatusChangesTabProps) => {
    const {
        reportsRange, setReportsRange,
    } = props;

    return (
        <>
            <Stack gap={3}>
                <DateTimeRangePicker range={reportsRange} setRange={setReportsRange} disabled={props.disabled} />
            </Stack>
        </>
    );
};

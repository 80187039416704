import { redirect } from "react-router-dom";
import { AppRoutes } from "../constants";

export function redirectIfUnauthorized(authorize: boolean): null | Response {
    if (authorize) {
        return null;
    } else {
        return redirect(AppRoutes.signIn);
    }
}

export function redirectIfAuthorized(authorize: boolean): null | Response {
    if (!authorize) {
        return null;
    } else {
        return redirect(AppRoutes.home);
    }
}

import React from "react";
import { ComplaintStatusChip } from "../../../../components/complaint-status-chip.component";
import { Chip } from "@mui/material";
import { IComplaintCardProps } from "../props";
import { UserChip } from "../../../../components/user-tooltip.component";
import { IComplaintFull } from "../../../../models";
import { useAppSelector } from "../../../../hooks";

export interface IComplaintCardChipsProps {
    complaint: IComplaintFull,
    reload: IComplaintCardProps["reload"],
}

export const ComplaintCardChips = (props: IComplaintCardChipsProps) => {
    const complaint = props.complaint;

    const myId = useAppSelector(state => state.session.token !== undefined ? state.session.userId : undefined);

    return (
        <>
            <ComplaintStatusChip status={complaint.status} />
            {
                complaint.assignedUserId !== undefined ? (
                    <UserChip userId={complaint.assignedUserId} labelBuilder={(user) => `Рассматривается ${user.id === myId ? "Вами" : user.username}`} />
                ) : (
                    <Chip label="Никем не рассматривается" />
                )
            }
            <UserChip userId={complaint.userId} labelBuilder={(user) => `Подана ${user.username}`} />
        </>
    );
};

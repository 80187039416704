import { statusLabelFrom } from "../components/complaint-status-chip.component";
import { AppRoutes } from "../constants";
import { ComplaintStatus, IUser, INotification, ISessionToken } from "../models";
import { IAppResponse, parseErrors, SOMETHING_HAPPENED_WRONG_ERROR } from "../utils";

export type INotificationCallback = (notification: INotification) => any;

interface INotificationResponse<T=string, P = any> {
    id: string;
    user_id: IUser["id"],
    type: T;
    send_at: string;
    viewed: boolean;
    payload: P;
}

export interface INotificationsService {
    getNotifications(skip: number, take: number): Promise<IAppResponse<INotification[]>>;
    getNotificationsCount(): Promise<IAppResponse<number>>;
    readNotification(untilNotificationId?: INotification["id"]): Promise<IAppResponse<boolean>>;
    listen(callback: INotificationCallback): void;
    unlisten(callback: INotificationCallback): void;
    close(): void;
}

function parseNotification(body: INotificationResponse): INotification {
    let message = "Неизвестный тип уведомления";
    let link: string | undefined;

    switch (body["type"]) {
    case "complaint_status_changed":
    {
        const status: ComplaintStatus = body.payload["status"];
        const complaintId: string = body.payload["complaint_id"];
        message =
              `Статус жалобы #${complaintId} был изменен на "${statusLabelFrom(status)}"`;
        link = AppRoutes.complaint(complaintId);
        break;
    }
    case "complaint_assignment":
    {
        const complaintId: string = body.payload["complaint_id"];
        message = `Жалоба #${complaintId} на рассмотрении`;
        link = AppRoutes.complaint(complaintId);
        break;
    }
    case "complaint_close_confirmed":
    case "complaint_close_rejected":
    {
        const complaintId: string = body.payload["complaint_id"];
        message =
              `Пользователь ${body["type"] == "complaint_close_confirmed" ? "подтвердил" : "отменил"} закрытие жалобы #${complaintId}`;
        link = AppRoutes.complaint(complaintId);
        break;
    }
    }
  
    return {
        id: body["id"],
        sendAt: new Date(body.send_at),
        link: link,
        message: message,
        viewed: body["viewed"],
    };
}

export class NotificationsService implements INotificationsService {
    private readonly _apiNotificationsPath;
    private readonly _wsPath;

    private readonly _defaultHeaders: HeadersInit;

    private readonly _handlers: Set<INotificationCallback>;
    private _connection: WebSocket | null;
    private readonly _token: ISessionToken | null;

    constructor(token: ISessionToken | undefined) {
        this._apiNotificationsPath = `${process.env.REACT_APP_API_PATH}/notifications`;
        this._wsPath = `${process.env.REACT_APP_WS_API_PATH}/notifications`;
        this._defaultHeaders = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        };
        if (token !== undefined) {
            this._defaultHeaders["Authorization"] = `${token.tokenType} ${token.token}`;
        }
        this._handlers = new Set();
        this._connection = null;
        this._token = token ?? null;

        if (this._token !== null) {
            this._setupConnection();
        }
    }

    async getNotifications(skip: number, take: number): Promise<IAppResponse<INotification[]>> {
        const query = new URLSearchParams({
            skip: skip.toString(),
            take: take.toString(),
        });

        try {
            const res = await fetch(`${this._apiNotificationsPath}/?${query.toString()}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: data.notifications.map(parseNotification),
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async getNotificationsCount(): Promise<IAppResponse<number>> {
        try {
            const res = await fetch(`${this._apiNotificationsPath}/unviewed`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.text();
    
                return {
                    result: Number.parseInt(data),
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }   
    }

    async readNotification(untilNotificationId?: INotification["id"]): Promise<IAppResponse<boolean>> {
        try {
            const res = await fetch(`${this._apiNotificationsPath}/read?${untilNotificationId === undefined ? "" : `related_notification_id=${untilNotificationId}`}`, {
                method: "POST",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                return {
                    result: true,
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }   
    }


    private _setupConnection() {
        if (this._token !== null) {
            if (this._connection !== null) {
                this._connection.close();
            }

            this._connection = new WebSocket(`${this._wsPath}/?token=${this._token.token}`);

            this._connection.onmessage = (message) => {
                try {
                    const content = parseNotification(JSON.parse(message.data));
                    this._handlers.forEach(handler => handler(content));
                } catch (e) {
                    console.error(e);
                }
            };

            this._connection.onerror = (error) => {
                console.error(error);
            };

            this._connection.onclose = () => {
                setTimeout(() => {
                    this._setupConnection();
                }, 1000);
            };
        }
    }

    listen(callback: INotificationCallback): void {
        this._handlers.add(callback);
    }

    unlisten(callback: INotificationCallback): void {
        this._handlers.delete(callback);
    }

    close(): void {
        if (this._connection !== null) {
            this._connection.close();
            this._connection = null;
        }
    }
}

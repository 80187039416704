import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ComplaintCard } from "./complaint-card/complaint-card.component";
import { useParams } from "react-router-dom";
import { ComplainFinishedStatus as ComplaintFinishedStatus, ServicesContext } from "../../services";
import { NOT_FOUND, SOMETHING_HAPPENED_WRONG_ERROR } from "../../utils";
import { useTitle } from "../../hooks";
import { ComplaintStatus } from "../../models";
import { GoBackButton } from "../../components/go-back.component";
import { AppRoutes } from "../../constants";
import { Box, Stack } from "@mui/material";
import { IComplaintCardActions, IComplaintCardProps, IComplaintCardReload } from "./complaint-card/props";

export const ComplaintPage = () => {
    useTitle("Жалоба - Активный гражданин");

    const { id: complaintId } = useParams();

    const [complaintData, setComplaintData] = useState<IComplaintCardProps["complaint"] | undefined>(undefined);

    const [actionsLoading, setActionsLoading] = useState(false);

    const {
        complaintsService,
    } = useContext(ServicesContext);

    const loadComplaint = useCallback(async () => {
        if (complaintData !== null) {
            setComplaintData(null);
        }

        try {
            const complaintRes = await complaintsService.getComplaint(complaintId ?? "");

            if (complaintRes.result !== undefined && complaintRes.result !== null) {
                const complaint = complaintRes.result;
                const typeRes = await complaintsService.getComplaintType(complaintRes.result.complaintTypeId);

                if (typeRes.result !== undefined && typeRes.result !== null) {
                    setComplaintData({
                        result: {
                            complaint: complaint,
                            type: typeRes.result,
                        }
                    });
                    return;
                }
            }

            setComplaintData({
                error: NOT_FOUND,
            });
        } catch (e) {
            console.error(e);

            setComplaintData({
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            });
        }
    }, [complaintsService, complaintData, setComplaintData]);

    const cardReload: IComplaintCardReload = useMemo(() => ({
        complaint: async () => {
            await loadComplaint();
        },
    }), [loadComplaint, complaintData]);

    const actions: IComplaintCardActions = useMemo(() => ({
        assign: async () => {
            if (complaintData?.result) {
                setActionsLoading(true);

                try {
                    const user = await complaintsService.assignComplaint(complaintData.result.complaint.id, null);

                    if (user.result) {
                        setComplaintData({
                            result: {
                                complaint: {
                                    ...complaintData.result.complaint,
                                    assignedUserId: user.result.id,
                                    status: ComplaintStatus.processing,
                                },
                                type: complaintData.result.type,
                            }
                        });
                    }
                } finally {
                    setActionsLoading(false);
                }
            }
        },
        close: async (status: ComplaintFinishedStatus) => {
            if (complaintData?.result) {
                setActionsLoading(true);

                try {
                    const res = await complaintsService.closeComplaint(complaintData.result.complaint.id, status);

                    if (res.result) {
                        setComplaintData({
                            result: {
                                complaint: {
                                    ...complaintData.result.complaint,
                                    status,
                                },
                                type: complaintData.result.type,
                            }
                        });
                    }

                    return res;
                } finally {
                    setActionsLoading(false);
                }
            }

            return {
                result: false,       
            };
        },
        export: () => {
            console.info("export");
        },
        print: () => {
            console.info("print");
        },
        report: () => {
            console.info("report");
        },         
    }), [complaintData, setActionsLoading, complaintsService, setComplaintData]);

    useEffect(() => {
        (async () => {
            if (complaintData === undefined) {
                await cardReload.complaint();
            }
        })();
    }, [cardReload, complaintData]);

    return (
        <Stack flexBasis="100%" spacing={1.5} sx={{ overflowY: "auto" }}>
            <Box>
                <GoBackButton navigateToWhenNoHistory={AppRoutes.home} />
            </Box>
            <ComplaintCard
                complaint={complaintData ?? null}
                reload={cardReload}
                actionsLoading={actionsLoading}
                actions={actions} />
        </Stack>
    );
};

import { Dispatch, Middleware, MiddlewareAPI, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_THEME_MODE_KEY } from "../../constants";
import { AppTheme, AppThemeMode } from "../../types";

export type IThemeState = {
    mode: AppThemeMode,
    theme: AppTheme
}

function getSystemTheme(): AppTheme {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? AppTheme.dark : AppTheme.light;
}

function getThemeFromThemeMode(mode: AppThemeMode): AppTheme {
    if (mode !== AppThemeMode.system) {
        return mode === AppThemeMode.dark ? AppTheme.dark : AppTheme.light;
    }
    return getSystemTheme();
}

function getStateFromLocalStorage(themeModeKey: string = LOCAL_STORAGE_THEME_MODE_KEY): IThemeState {
    const mode = (localStorage.getItem(themeModeKey) as AppThemeMode | null) ?? AppThemeMode.system;
    return {
        mode,
        theme: getThemeFromThemeMode(mode)
    };
}

const THEME_INITIAL_STATE = getStateFromLocalStorage();

export const themeSlice = createSlice({
    name: "theme",
    initialState: THEME_INITIAL_STATE,
    reducers: {
        setThemeMode(state, action: PayloadAction<AppThemeMode>) {
            state.mode = action.payload;
            state.theme = getThemeFromThemeMode(action.payload);
        },
    }
});

export const themeActions = themeSlice.actions;
export const themeReducer = themeSlice.reducer;

export function createListenThemeChangedMiddleware(themeModeKey: string = LOCAL_STORAGE_THEME_MODE_KEY): Middleware {
    const media = window.matchMedia("(prefers-color-scheme: dark)");
    let listener: (() => void) | null = null;

    return (api: MiddlewareAPI<Dispatch, { theme: IThemeState }>) => {
        const listenSystem = () => {
            listener = () => {
                api.dispatch(themeActions.setThemeMode(AppThemeMode.system));
            };
            media.addEventListener("change", listener);
        };

        const removeListenerSystem = () => {
            if (listener !== null) {
                media.removeEventListener("change", listener);
                listener = null;
            }
        };

        if (api.getState().theme.mode === AppThemeMode.system) {
            listenSystem();
        }

        return (next) => (action) => {
            if (themeActions.setThemeMode.match(action)) {
                if (action.payload === AppThemeMode.system) {
                    localStorage.removeItem(themeModeKey);
                    if (listener === null) {
                        listenSystem();
                    }
                } else {
                    localStorage.setItem(themeModeKey, action.payload);
                    if (listener !== null) {
                        removeListenerSystem();
                    }
                }
            }
            next(action);
        };
    };
}

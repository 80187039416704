import { ISessionToken, IUser, IUserFull, UserRole } from "../models";
import { IAppResponse, SOMETHING_HAPPENED_WRONG_ERROR, parseErrors } from "../utils";
import { snakeCase } from "../utils/snake-case";

export interface ISignInRequest {
    username: string,
    password: string,
}

export enum GetUsersOrderStrategy {
    byUsername = 1,
    byFio = 2,
    byEmail = 3,
}

export interface IGetUsersRequest {
    skip: number;
    take: number;
    orderStrategy: GetUsersOrderStrategy;
    reverse?: boolean;
    roleFilter?: IUser["role"][];
    textFilter?: string;
}

export interface IGetUsersResponse {
    users: IUser[],
    total: number,
}

export interface IRestorePasswordRequest {
    data: string;
}

export interface ICreateUserByAdminRequest {
    username: string;
    password: string;
    email: string;
    fio: string;
    phone: string;
    address: string;
    role: UserRole;
    accessOverrides: Record<string, boolean>;
}

export interface IUpdateUserByAdminRequest {
    username?: string;
    password?: string;
    email?: string;
    fio?: string;
    phone?: string;
    address?: string;
    role?: UserRole;
    accessesOverrides?: Record<string, boolean>;
}

export interface IUsersService {
    signIn(req: ISignInRequest): Promise<IAppResponse<ISessionToken, ISignInRequest>>;
    restorePassword(req: IRestorePasswordRequest): Promise<IAppResponse<boolean, IRestorePasswordRequest>>;
    getUser(id: IUser["id"]): Promise<IAppResponse<IUserFull | null>>;
    getUsers(req: IGetUsersRequest): Promise<IAppResponse<IGetUsersResponse>>;
    getRolesAccesses(): Promise<IAppResponse<Record<UserRole, string[]>>>;
    createUserByAdmin(req: ICreateUserByAdminRequest): Promise<IAppResponse<boolean, ICreateUserByAdminRequest>>;
    updateUserByAdmin(userId: IUser["id"], req: IUpdateUserByAdminRequest): Promise<IAppResponse<boolean, IUpdateUserByAdminRequest>>;
    deleteUser(id: IUser["id"]): Promise<IAppResponse<boolean>>;
}

export class UsersService implements IUsersService {
    private readonly _apiUsersPath;

    private readonly _defaultHeaders: HeadersInit;

    constructor(token: ISessionToken | undefined) {
        this._apiUsersPath = `${process.env.REACT_APP_API_PATH}/users`;
        this._defaultHeaders = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        };
        if (token !== undefined) {
            this._defaultHeaders["Authorization"] = `${token.tokenType} ${token.token}`;
        }
    }

    async signIn(req: ISignInRequest): Promise<IAppResponse<ISessionToken, ISignInRequest>> {
        try {
            const res = await fetch(`${this._apiUsersPath}/signin`, {
                method: "POST",
                body: JSON.stringify(req),
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: {
                        token: data["access_token"],
                        tokenType: data["token_type"],
                    },
                };
            }

            return {
                error: await parseErrors<ISignInRequest>(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async restorePassword(req: IRestorePasswordRequest): Promise<IAppResponse<boolean, IRestorePasswordRequest>> {
        try {
            const res = await fetch(`${this._apiUsersPath}/recovery-password`, {
                method: "POST",
                body: JSON.stringify(req),
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                return {
                    result: true,
                };
            }

            return {
                error: await parseErrors<IRestorePasswordRequest>(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    private readonly _usersCache: Map<IUser["id"], IUserFull> = new Map();
    private readonly _usersRequests: Map<IUser["id"], Promise<IAppResponse<IUserFull | null>>> = new Map();

    async _getUser(id: number): Promise<IAppResponse<IUserFull | null>> {
        try {
            const res = await fetch(`${this._apiUsersPath}/get/${id}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.status === 404) {
                return {
                    result: null,
                };
            }

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: {
                        id: data.id,
                        username: data.username,
                        email: data.email,
                        phone: data.phone,
                        fio: data.fio,
                        address: data.address,
                        role: data.role,
                        accesses: data.accesses,
                    }, 
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async getUser(id: number): Promise<IAppResponse<IUserFull | null>> {
        if (this._usersCache.has(id)) {
            return {
                result: this._usersCache.get(id)!,
            };
        }

        if (!this._usersRequests.has(id)) {
            const promise = this._getUser(id);
            promise.finally(() => {
                if (this._usersRequests.get(id) === promise) {
                    this._usersRequests.delete(id);
                }
            });
            this._usersRequests.set(id, promise);
        }

        const response = await this._usersRequests.get(id)!;
        if (response.result) {
            this._usersCache.set(id, response.result);
        }

        return response;
    }

    async getUsers(req: IGetUsersRequest): Promise<IAppResponse<IGetUsersResponse>> {
        const query = new URLSearchParams({
            skip: req.skip.toString(),
            take: req.take.toString(),
            order_by: req.orderStrategy.toString(),
            reverse: (req.reverse ?? false).toString(),
        });

        if (req.textFilter !== undefined) {
            query.append("text_filter", req.textFilter.toString());
        }
        if (req.roleFilter !== undefined) {
            for (const role of req.roleFilter) {
                query.append("role_filter", role.toString());
            }
        }

        try {
            const res = await fetch(`${this._apiUsersPath}/get?${query.toString()}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.json();

                const users: IUser[] = data.result.map((entry: any) => ({
                    id: entry.id,
                    username: entry.username,
                    email: entry.email,
                    phone: entry.phone,
                    fio: entry.fio,
                    address: entry.address,
                    role: entry.role,
                }));

                return {
                    result: {
                        users,
                        total: data.count,
                    }, 
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async getRolesAccesses(): Promise<IAppResponse<Record<UserRole, string[]>>> {
        try {
            const res = await fetch(`${this._apiUsersPath}/roles`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.json();

                return {
                    result: data,
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async createUserByAdmin(req: ICreateUserByAdminRequest): Promise<IAppResponse<boolean, ICreateUserByAdminRequest>> {
        try {
            const res = await fetch(`${this._apiUsersPath}/admin/create`, {
                method: "POST",
                body: JSON.stringify({
                    username: req.username,
                    password: req.password,
                    email: req.email,
                    fio: req.fio,
                    phone: req.phone,
                    address: req.address,
                    role: req.role,
                    accesses_overrides: req.accessOverrides,
                }),
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                return {
                    result: true,
                };
            }

            return {
                error: await parseErrors<ICreateUserByAdminRequest>(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async updateUserByAdmin(userId: IUser["id"], req: IUpdateUserByAdminRequest): Promise<IAppResponse<boolean, IUpdateUserByAdminRequest>> {
        const body: Record<string, any> = {};

        for (const key in req) {
            if (req[key as keyof IUpdateUserByAdminRequest] !== undefined) {
                body[snakeCase(key)] = req[key as keyof IUpdateUserByAdminRequest];
            }
        }

        try {
            const res = await fetch(`${this._apiUsersPath}/admin/update?user_id=${userId}`, {
                method: "POST",
                body: JSON.stringify(body),
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                if (this._usersCache.has(userId)) {
                    const user = this._usersCache.get(userId)!;

                    const updates: Partial<IUserFull> = {
                        ...req,
                    };

                    delete (updates as unknown as IUpdateUserByAdminRequest)["accessesOverrides"];

                    if (req.accessesOverrides !== undefined) {
                        const overrides = Array.from(Object.entries(req.accessesOverrides));
                        const mustHave = new Set(overrides.filter(([, value]) => value).map(([key, ]) => key));
                        const shouldNotHave = overrides.filter(([, value]) => !value).map(([key, ]) => key);
                        updates.accesses = Array.from(new Set([...user.accesses, ...Array.from(mustHave)])).filter(override => !shouldNotHave.includes(override));
                    }

                    this._usersCache.set(userId, {
                        ...user,
                        ...updates,
                    } as IUserFull);
                }

                return {
                    result: true,
                };
            }

            return {
                error: await parseErrors<IUpdateUserByAdminRequest>(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async deleteUser(id: IUser["id"]): Promise<IAppResponse<boolean>> {
        try {
            const res = await fetch(`${this._apiUsersPath}/get/${id}`, {
                method: "DELETE",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                return {
                    result: true,
                };
            }

            return {
                error: await parseErrors<ISignInRequest>(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }        
    }
}

import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit";
import type { Middleware, PayloadAction } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_TOKEN_KEY } from "../../constants";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { ISessionToken, UserRole } from "../../models";

type IAuthorizedState = {
    token: ISessionToken;
    userId: number;
    role: UserRole;
    accesses: string[];
    expiredAt: number;
}

export type ISessionState = IAuthorizedState | {
    token: undefined;
}

function stateFromToken(token: ISessionToken): IAuthorizedState {
    const jwtContent = jwtDecode<JwtPayload & {
        role: string,
        access: string,
    }>(token.token);

    return {
        token,
        userId: Number.parseInt(jwtContent.sub!),
        role: Number.parseInt(jwtContent.role) as UserRole,
        accesses: jwtContent.access.split(","),
        expiredAt: 1000 * jwtContent.exp!,
    };
}

const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

const SESSION_INITIAL_STATE: ISessionState = token === null ? {
    token: undefined,
} : stateFromToken(JSON.parse(token));

export const sessionSlice = createSlice({
    name: "session",
    initialState: SESSION_INITIAL_STATE as ISessionState,
    reducers: {
        setSession(_, action: PayloadAction<ISessionToken | null>) {
            return action.payload !== null ? stateFromToken(action.payload) : {
                token: undefined,
            };
        }
    }
});

export const sessionActions = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;

export function createListenSessionChangedMiddleware(
    tokenKey: string = LOCAL_STORAGE_TOKEN_KEY,
    autoSignOut = true,
): Middleware {
    const listener = createListenerMiddleware();

    let signOutTimeout: NodeJS.Timeout | undefined;

    listener.startListening({
        actionCreator: sessionSlice.actions.setSession,
        effect: (action, api) => {
            if (signOutTimeout !== undefined) {
                clearTimeout(signOutTimeout);
                signOutTimeout = undefined;
            }

            if (action.payload === null) {
                localStorage.removeItem(tokenKey);
            } else {
                localStorage.setItem(tokenKey, JSON.stringify(action.payload));
                if (autoSignOut) {
                    const state = stateFromToken(action.payload);
                    const expired = new Date(state.expiredAt);

                    const now = new Date();
                    const diff = +expired - +now;

                    signOutTimeout = setTimeout(() => {
                        api.dispatch(sessionSlice.actions.setSession(null));
                        signOutTimeout = undefined;
                    }, diff);
                }
            }
        }
    });

    return listener.middleware;
}

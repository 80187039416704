import React from "react";
import { CardContent, Divider, Stack, Typography } from "@mui/material";

import { IComplaintCardProps } from "../props";
import { IComplaintFull, IComplaintType } from "../../../../models";
import { ChatComponent } from "../../../chat/chat.component";
import { ComplaintCardHeader } from "./card-header.component";
import { ComplaintCardChips } from "./card-chips.component";
import { ComplaintCardContent } from "./card-content.component";
import { ComplaintCardButtons } from "./card-buttons.component";
import { useAppSelector } from "../../../../hooks";

type ILoadedComplaintProps = {
    complaint: IComplaintFull,
    type: IComplaintType,
    actions: IComplaintCardProps["actions"],
    reload: IComplaintCardProps["reload"],
    actionsLoading: boolean,
}

export const LoadedComplaintCardContent = (props: ILoadedComplaintProps) => {
    const me = useAppSelector(state => state.session.token != null ? state.session.userId : null);
    const complaint = props.complaint;
    const type = props.type;

    return (
        <>
            <ComplaintCardHeader complaint={complaint} type={type} />
            <CardContent sx={{ flexBasis: "100%", display: "flex", flexDirection: "column", alignItems: "stretch", overflowY: "auto" }}>
                <Stack direction="row" spacing={2} sx={{ pb: 3 }}>
                    <ComplaintCardChips
                        complaint={complaint}
                        reload={props.reload} />
                </Stack>
                <Stack direction="row" spacing={4} alignItems="stretch" flexBasis="100%" sx={{ overflowY: "auto" }}>
                    <Stack direction="column" alignItems="stretch" spacing={1.5} flexBasis="40vw" sx={{ overflowY: "auto" }}>
                        <Typography variant="subtitle1"><b>О жалобе:</b></Typography>
                        <Stack direction="column" flexBasis="100%" alignItems="stretch" spacing={1} sx={{ overflowY: "auto", pr: 1 }}>
                            <ComplaintCardContent complaint={complaint} type={type} />
                        </Stack>
                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                            <ComplaintCardButtons
                                complaint={complaint}
                                actions={props.actions}
                                actionsLoading={props.actionsLoading} />
                        </Stack>
                    </Stack>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Stack direction="column" spacing={1} flexBasis="100%" sx={{ overflowX: "auto" }}>
                        {
                            me != null && (
                                complaint.assignedUserId == me || complaint.userId == me
                            ) ? <>
                                    <Typography variant="subtitle1"><b>Чат:</b></Typography>
                                    <ChatComponent chatId={complaint.chatId} />
                                </> : undefined
                        }
                    </Stack>
                </Stack>
            </CardContent>
        </>
    );
};

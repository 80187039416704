import { ChatRecordType, IChat, IChatActionRecord, IChatMessageRecord, IChatRecord, IChatRecordBase, ISessionToken } from "../models";
import { AppError, IAppResponse, NOT_CONNECTION, SOMETHING_HAPPENED_WRONG_ERROR, parseErrors } from "../utils";

export type IChatHandler<T = any> = (type: string, payload: T) => void;

export enum GetRecordsDirection {
    forward = "forward",
    backward = "backward"
}

export interface IGetRecordsRequest {
    chatId: IChat["id"];
    relativeRecordId?: IChatRecord["id"];
    includeRelativeRecord: boolean;
    direction: GetRecordsDirection;
    take: number;
}

export interface IChatService {
    getChat(id: IChat["id"]): Promise<IAppResponse<IChat | null>>;
    getRecords(req: IGetRecordsRequest): Promise<IAppResponse<IChatRecord[]>>;
    sendMessage(chat: IChat["id"], message: string): Promise<void>;
    viewUntil(chat: IChat["id"], recordId: IChatRecord["id"]): Promise<void>;

    listen<T>(handler: IChatHandler<T>): void;
    unlisten<T>(handler: IChatHandler<T>): void;
    close(): void;
}

export function parseRecord(body: any): IChatRecord {
    const recordBase: IChatRecordBase = {
        id: body.id,
        type: body.type,
        chatId: body.chat_id,
        userId: body.user_id,
        sendAt: new Date(body.send_at),
        viewed: body.viewed,
    };
    switch (body.type) {
    case ChatRecordType.message: {
        return {
            ...recordBase,
            message: body.message,
            medias: body.medias,
        } as IChatMessageRecord;
    }
    case ChatRecordType.action: {
        return {
            ...recordBase,
            actionType: body.action_type,
        } as IChatActionRecord;
    }
    default: {
        throw new AppError(`not recognizing record type: ${body.type}`);
    }
    }
}

export class ChatsService implements IChatService {
    private readonly _apiChatsPath;
    private readonly _wsPath;

    private readonly _handlers: Set<IChatHandler>;

    private readonly _defaultHeaders: HeadersInit;

    private _connection: WebSocket | null;
    private readonly _token: ISessionToken | null;

    constructor(token: ISessionToken | undefined) {
        this._apiChatsPath = `${process.env.REACT_APP_API_PATH}/chats`;
        this._wsPath = `${process.env.REACT_APP_WS_API_PATH}/chats`;
        this._defaultHeaders = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        };
        if (token !== undefined) {
            this._defaultHeaders["Authorization"] = `${token.tokenType} ${token.token}`;
        }
        this._handlers = new Set();

        this._connection = null;
        this._token = token ?? null;

        if (this._token !== null) {
            this._setupConnection();
        }
    }

    async getChat(id: IChat["id"]): Promise<IAppResponse<IChat | null>> {
        try {
            const res = await fetch(`${this._apiChatsPath}/${id}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.status === 404) {
                return {
                    result: null,
                };
            }

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: {
                        id: data.id,
                        members: data.members,
                        lastViewed: data.lastViewed,
                        unread: data.unread,
                        closed: data.closed,
                    },
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async getRecords(req: IGetRecordsRequest): Promise<IAppResponse<IChatRecord[]>> {
        try {
            const query = new URLSearchParams({
                chat_id: req.chatId,
                include_relative_record: req.includeRelativeRecord.toString(),
                direction: req.direction,
                take: req.take.toString(),
            });

            if (req.relativeRecordId !== undefined) {
                query.append("relative_record_id", req.relativeRecordId);
            }

            const res = await fetch(`${this._apiChatsPath}/${req.chatId}/records?${query.toString()}`, {
                method: "GET",
                headers: this._defaultHeaders,
            });

            if (res.ok) {
                const data = await res.json();
    
                return {
                    result: data.records.map((record: any) => parseRecord(record)),
                };
            }

            return {
                error: await parseErrors(res),
            };
        } catch (e) {
            console.error(e);

            return {
                error: SOMETHING_HAPPENED_WRONG_ERROR,
            };
        }
    }

    async sendMessage(chatId: IChat["id"], message: string): Promise<void> {
        if (this._connection !== null) {
            this._connection.send(JSON.stringify({
                type: "send_message",
                payload: {
                    chat_id: chatId,
                    message,
                    medias: [],
                }
            }));
        } else {
            throw NOT_CONNECTION;
        }
    }

    async viewUntil(chatId: IChat["id"], recordId: IChatRecord["id"]): Promise<void> {
        if (this._connection !== null) {
            this._connection.send(JSON.stringify({
                type: "view_record",
                payload: {
                    chat_id: chatId,
                    view_until_id: recordId,
                },
            }));
        } else {
            throw NOT_CONNECTION;
        }
    }

    private _setupConnection() {
        if (this._token !== null) {
            if (this._connection !== null) {
                this._connection.close();
            }

            this._connection = new WebSocket(`${this._wsPath}/?token=${this._token.token}`);

            this._connection.onmessage = (message) => {
                try {
                    const content = JSON.parse(message.data);
                    this._handlers.forEach(handler => handler(content["type"], content["payload"]));
                } catch (e) {
                    console.error(e);
                }
            };

            this._connection.onerror = (error) => {
                this._handlers.forEach(handler => handler("error", error.type));
            };

            this._connection.onclose = () => {
                setTimeout(() => {
                    this._setupConnection();
                }, 1000);
            };
        }
    }

    listen<T>(handler: IChatHandler<T>) {
        this._handlers.add(handler);
    }

    unlisten<T>(handler: IChatHandler<T>) {
        this._handlers.delete(handler);
    }

    close() {
        if (this._connection !== null) {
            this._connection.close();
            this._connection = null;
        }
    }
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { createAppStore } from "./store/store";
import { Provider } from "react-redux";
import moment from "moment";

moment.locale("ru");

moment.updateLocale("ru", {
    months: [
        "янв", "фев", "мар",
        "апр", "май", "июн",
        "июл", "авг", "сен",
        "окт", "ноя", "дек",
    ],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const store = createAppStore();

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

reportWebVitals(console.info);

import React from "react";
import { ComplaintStatus } from "../models";
import { Chip, ChipOwnProps, ChipProps } from "@mui/material";

export interface IComplaintStatusChipProps {
    status: ComplaintStatus;
}

export function statusLabelFrom(status: ComplaintStatus): string {
    switch (status) {
    case ComplaintStatus.unchecked: {
        return "Не рассматривается";
    }
    case ComplaintStatus.processing: {
        return "Обрабатывается";
    }
    case ComplaintStatus.checked: {
        return "Рассмотрено";
    }
    case ComplaintStatus.violatorFined: {
        return "Нарушитель оштрафован";
    }
    case ComplaintStatus.violatorNotFound: {
        return "Нарушитель не найден";
    }
    }
}

export function statusColorFrom(status: ComplaintStatus): ChipOwnProps["color"] {
    switch (status) {
    case ComplaintStatus.unchecked: {
        return "error";
    }
    case ComplaintStatus.processing: {
        return "warning";
    }
    case ComplaintStatus.checked: 
    case ComplaintStatus.violatorFined: {
        return "success";
    }
    case ComplaintStatus.violatorNotFound: {
        return "error";
    }
    }
}

export const ComplaintStatusChip = ({ status, ...props }: ChipProps & IComplaintStatusChipProps) => (
    <Chip {...props} color={statusColorFrom(status)} label={statusLabelFrom(status)} />
);

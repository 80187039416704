import React, { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { MenuItem, ListItemIcon, ListItemText, Select, SelectChangeEvent, SvgIconTypeMap } from "@mui/material";
import { themeActions } from "../store/slices/theme.slice";
import { AppThemeMode } from "../types";

import SunIcon from "@mui/icons-material/WbSunnyRounded";
import MoonIcon from "@mui/icons-material/DarkModeRounded";
import ThemeIcon from "@mui/icons-material/PaletteRounded";
import { OverridableComponent } from "@mui/material/OverridableComponent";

const themeMenuId = "theme-menu";

const themeModeToIcon: Record<AppThemeMode, OverridableComponent<SvgIconTypeMap>> = {
    [AppThemeMode.system]: ThemeIcon,
    [AppThemeMode.light]: SunIcon,
    [AppThemeMode.dark]: MoonIcon,
};

export const ThemeSelect = () => {
    const theme = useAppSelector(state => state.theme);
    const dispatch = useAppDispatch();

    const changeTheme = useCallback((event: SelectChangeEvent<AppThemeMode>) => {
        dispatch(themeActions.setThemeMode(event.target.value as AppThemeMode));
    }, [dispatch]);

    const ThemeIconComponent = themeModeToIcon[theme.mode];

    return (
        <Select
            id={themeMenuId}
            value={theme.mode}
            variant="filled"
            disableUnderline
            sx={{ borderRadius: 1 }}
            inputProps={{ sx: { display: "flex", paddingTop: 2, paddingBottom: 2 } }}
            renderValue={() => <ThemeIconComponent fontSize="small" />}
            onChange={changeTheme}
        >
            <MenuItem value={AppThemeMode.light}>
                <ListItemIcon>
                    <SunIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Светлая тема</ListItemText>
            </MenuItem>
            <MenuItem value={AppThemeMode.dark}>
                <ListItemIcon>
                    <MoonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Темная тема</ListItemText>
            </MenuItem>
            <MenuItem value={AppThemeMode.system}>
                <ListItemIcon>
                    <ThemeIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Системная тема</ListItemText>
            </MenuItem>
        </Select>

    );
};
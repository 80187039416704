import { LoaderFunction, redirect } from "react-router-dom";
import { ISessionState } from "../../../store/slices";
import { AppRoutes } from "../../../constants";

export function requireAccessAction(session: ISessionState, ...access: string[]): LoaderFunction {
    return () => {
        if (session.token === undefined || access.some(accessToken => !session.accesses.includes(accessToken))
        ) {
            return redirect(session.token === undefined ? AppRoutes.signIn : AppRoutes.home);
        }
        return true;
    };
}
import React, { useCallback } from "react";
import { Box, Button, Card, CardActions, CardContent, Container, Stack, Typography } from "@mui/material";
import { isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom";
import WarningIcon from "@mui/icons-material/ReportRounded";
import { AppRoutes } from "../../constants";

const SomethingHappenedWrong = () => {
    const navigate = useNavigate();
    const canGoBack = window.history.state && window.history.state.idx > 0;

    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const goHome = useCallback(() => {
        navigate(AppRoutes.root);
    }, [navigate]);

    return (<Container maxWidth="tn">
        <Card>
            <CardContent>
                <Stack justifyContent="center">
                    <Box display="flex" justifyContent="center">
                        <WarningIcon fontSize="large" sx={{ width: "10vmin", height: "10vmin" }} color="error" />
                    </Box>
                    <Typography variant="h6" align="center">Что-то пошло не так</Typography>
                    <Typography variant="caption" align="center">Попробуйте позже или свяжитесь с нами</Typography>
                </Stack>
            </CardContent>
            <CardActions sx={{ pb: 2, px: 2 }}>
                <Box flexGrow={1} />
                <Button onClick={goHome} variant={ canGoBack ? "text" : "contained" } color="error" size="small">На главную</Button>
                { canGoBack ? <Button onClick={goBack} variant="contained" color="error" size="small">Назад</Button> : undefined }
            </CardActions>
        </Card>
    </Container>);
};

const NotFoundPage = () => {
    const navigate = useNavigate();
    const canGoBack = window.history.state && window.history.state.idx > 0;

    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const goHome = useCallback(() => {
        navigate(AppRoutes.root);
    }, [navigate]);

    return (<Container maxWidth="tn">
        <Card>
            <CardContent>
                <Stack justifyContent="center">
                    <Typography variant="h1" align="center" color="error">404</Typography>
                    <Typography variant="h6" align="center">Страница не найдена</Typography>
                </Stack>
            </CardContent>
            <CardActions sx={{ pb: 2, px: 2 }}>
                <Box flexGrow={1} />
                <Button onClick={goHome} variant={ canGoBack ? "text" : "contained" } color="error" size="small">На главную</Button>
                { canGoBack ? <Button onClick={goBack} variant="contained" color="error" size="small">Назад</Button> : undefined }
            </CardActions>
        </Card>
    </Container>);
};

export const ErrorHandler = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error) && error.status === 404) {
        return (
            <NotFoundPage />
        );
    } else {
        return (
            <SomethingHappenedWrong />
        );
    }
};

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GoBackButton } from "../../components/go-back.component";
import { AppRoutes } from "../../constants";
import { IUser, roleToLabel, UserRole } from "../../models";
import { GetUsersOrderStrategy, IUsersService, ServicesContext } from "../../services";
import CancelablePromise, { cancelable } from "cancelable-promise";

import { Typography, TableContainer, TablePagination, Stack, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, CircularProgress, useTheme, Select, SelectChangeEvent, MenuItem, Input, IconButton, TextField, Divider, Box, Menu, ListItemIcon, ListItemText, Dialog, DialogContent, DialogActions, DialogContentText, Button } from "@mui/material";
import { AppError, SOMETHING_HAPPENED_WRONG_ERROR } from "../../utils";

import SelectedIcon from "@mui/icons-material/CheckRounded";
import ShownFilterIcon from "@mui/icons-material/FilterAltRounded";
import HiddenFilterIcon from "@mui/icons-material/FilterAltOffRounded";
import MoreIcon from "@mui/icons-material/MoreVertRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";

import { useAppSelector, useTitle } from "../../hooks";
import { CreateUserButton, UpdateUserButton } from "./user-buttons";
import { useDispatch } from "react-redux";
import { sessionActions } from "../../store/slices";

interface IUserRow {
    username: string;
    email?: string;
    role: string;
    fio: string;
}

interface IUserColumn {
    id: keyof IUserRow;
    label: string;
    sortBy?: GetUsersOrderStrategy;
}

function usersHeaders(): IUserColumn[] {
    return [
        {
            id: "username",
            label: "Логин",
            sortBy: GetUsersOrderStrategy.byUsername,
        },
        {
            id: "role",
            label: "Роль",
        },
        {
            id: "email",
            label: "Почта",
            sortBy: GetUsersOrderStrategy.byEmail,
        },

        {
            id: "fio",
            label: "ФИО",
            sortBy: GetUsersOrderStrategy.byFio,
        },
    ];
}

interface IUsersTableProps {
    onSortChanged: (strategy: GetUsersOrderStrategy, reverse: boolean) => void;
    reverse: boolean;
    orderBy: GetUsersOrderStrategy;
}

function UsersTableHead(props: IUsersTableProps) {
    const { orderBy, reverse, onSortChanged } = props;

    const order = reverse ? "desc" : "asc";
  
    return (
        <TableHead>
            <TableRow>
                {usersHeaders().map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.sortBy ? order : false}
                        align="center"
                    >
                        {
                            headCell.sortBy !== undefined ? (
                                <TableSortLabel
                                    active={orderBy === headCell.sortBy}
                                    direction={orderBy === headCell.sortBy ? order : "asc"}
                                    onClick={ () => {
                                        if (orderBy === headCell.sortBy) {
                                            onSortChanged(orderBy, !reverse);
                                        } else {
                                            onSortChanged(headCell.sortBy!, reverse);
                                        }
                                    }}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            ) : headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface IDeleteUserButtonProps {
    userId: IUser["id"],
    onCancel: () => void;
    onDelete: () => void;
}

function DeleteUserButton(props: IDeleteUserButtonProps) {
    const dispatch = useDispatch();
    const { usersService } = useContext(ServicesContext);

    const session = useAppSelector(state => state.session.token !== undefined ? state.session : undefined);

    const [isModalOpened, setModalOpened] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const openModal = useCallback(() => {
        setModalOpened(true);
    }, [setModalOpened]);

    const closeModal = useCallback(() => {
        setModalOpened(false);
        props.onCancel();
    }, [setModalOpened]);

    const deleteAccount = useCallback(async () => {
        try {
            setLoading(true);
            setError(undefined);
            const res = await usersService.deleteUser(props.userId);
            if (res.result) {
                if (props.userId === session?.userId) {
                    dispatch(sessionActions.setSession(null));
                } else {
                    setModalOpened(false);
                    props.onDelete();
                }
            } else if (res.result === undefined) {
                setError(res.error.mainMessage);
            }
        } catch (e) {
            setError(SOMETHING_HAPPENED_WRONG_ERROR.mainMessage);
        } finally {
            setLoading(false);
        }
    }, [dispatch, props.userId, usersService, session]);

    return (
        <>
            <MenuItem
                onClick={openModal}
                disabled={loading}
            >
                <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Удалить</ListItemText>
            </MenuItem>
            <Dialog open={isModalOpened} onClose={closeModal}>
                <DialogContent>
                    <DialogContentText>Вы действительно хотите удалить аккаунт?</DialogContentText>
                    {
                        error ? (
                            <Typography variant="caption" color="error">{error}</Typography>
                        ) : undefined
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={closeModal} variant="text" color="secondary" size="medium">
                        Отмена
                    </Button>
                    <Button startIcon={loading! ? <CircularProgress size={16} /> : undefined} disabled={loading} onClick={deleteAccount} variant="contained" color="secondary" size="medium">
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export const UsersPage = () => {
    useTitle("Пользователи - Активный гражданин");

    const session = useAppSelector(state => state.session.token !== undefined ? state.session : undefined);

    const canAddUser = useMemo(() => {
        return session?.accesses.includes("users.create");
    }, [session]);

    const canActions = useMemo(() => {
        return {
            canDelete: session?.accesses.includes("users.delete") ?? false,
            canChangeAccess: session?.accesses.includes("users.change_access") ?? false,
        };
    }, [session]);

    const theme = useTheme();

    const { usersService } = useContext(ServicesContext);

    const [userIdMenu, setUserIdMenu] = useState<IUser["id"] | null>(null);
    const [contextMenu, setContextMenu] = useState<{ top: number, left: number } | undefined>();

    const onRowActionsOpened = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const user = e.currentTarget.dataset.user;

        if (user !== undefined) {
            setContextMenu({
                top: e.clientY - 6,
                left: e.clientX + 2,
            });
            setUserIdMenu(Number(user));
        }
    }, [contextMenu, setContextMenu]);

    const handleCloseMenu = useCallback(() => {
        setContextMenu(undefined);
    }, [setContextMenu, setUserIdMenu]);

    const rowStyles = useMemo(() => (
        {
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.background.paper,
            },
            "&:hover td": {
                backgroundColor: `${theme.palette.background.selected} !important`,
                transition: "0.25s ease-in-out",
                transitionProperty: "background-color",
            },
        }
    ), [theme]);

    const [isFiltersShowed, setFiltersShowed] = useState(false);

    const [take, setTake] = useState(10);
    const [page, setPage] = useState(0);
    const [roleFilter, setRoleFilter] = useState<UserRole[]>([UserRole.manager, UserRole.admin]);
    const [textFilter, setTextFilter] = useState("");

    const [loading, setLoading] = useState(false);
    const [promise, setPromise] = useState<CancelablePromise<Awaited<ReturnType<IUsersService["getUsers"]>>> | null>(null);
    const [data, setData] = useState<Awaited<ReturnType<IUsersService["getUsers"]>> | null>(null);

    const [sort, setSort] = useState({
        strategy: GetUsersOrderStrategy.byUsername,
        reverse: false,
    });

    const takeChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTake(Number(e.target.value));
    }, [setTake]);

    const pageChanged = useCallback((_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    }, [setPage]);

    const sortChanged = useCallback((strategy: GetUsersOrderStrategy, reverse: boolean) => {
        setSort({
            strategy,
            reverse,
        });
    }, [setSort]);
    
    const roleChanged = useCallback((event: SelectChangeEvent<UserRole[]>) => {
        setRoleFilter(event.target.value as UserRole[]);
    }, [setRoleFilter]);

    const textFilterChanged = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTextFilter(e.target.value);
    }, [setTextFilter]);

    const toggleFilter = useCallback(() => {
        setFiltersShowed(!isFiltersShowed);
    }, [isFiltersShowed, setFiltersShowed]);

    const [forceUpdate, forceSet] = useState({});

    useEffect(() => {
        if (promise !== null) {
            promise.cancel();
        }

        setLoading(true);

        const trimmedTextFilter = textFilter.trim();

        const loadPromise = cancelable<Awaited<ReturnType<IUsersService["getUsers"]>>>(
            usersService.getUsers({
                skip: page * take,
                take,
                orderStrategy: sort.strategy,
                reverse: sort.reverse,
                roleFilter: roleFilter.length !== 0 ? roleFilter : undefined,
                textFilter: trimmedTextFilter.length === 0 ? undefined : trimmedTextFilter,
            })
        );

        loadPromise.then((res) => {
            setData(res);
        }).catch((e) => {
            if (e instanceof AppError) {
                setData({
                    error: e,
                });
            } else {
                console.error(e);
                setData({
                    error: SOMETHING_HAPPENED_WRONG_ERROR,
                });
            }
        }).finally(() => {
            setLoading(false);
            setPromise(null);
        });

        setPromise(loadPromise);
    }, [take, page, roleFilter, textFilter, sort.reverse, sort.strategy, forceUpdate]);

    return <Stack flexBasis="100%" spacing={1.5} sx={{ overflowY: "auto" }}>
        <Box>
            <GoBackButton navigateToWhenNoHistory={AppRoutes.home} />
        </Box>
        <Typography variant="h4">Пользователи</Typography>
        <Menu
            id="user-menu"
            anchorReference="anchorPosition"
            anchorPosition={contextMenu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            keepMounted
            open={contextMenu !== undefined}
            onClose={handleCloseMenu}
        >
            {
                canActions.canChangeAccess && userIdMenu !== null ? (
                    <UpdateUserButton userId={userIdMenu} onUpdated={() => {
                        forceSet({});
                        handleCloseMenu();
                    }} onCancel={() => {
                        setUserIdMenu(null);
                        handleCloseMenu();
                    }} />
                ) : undefined
            }
            {
                canActions.canDelete && userIdMenu !== null ? (
                    <DeleteUserButton userId={userIdMenu} onCancel={() => {
                        setUserIdMenu(null);
                        handleCloseMenu();
                    }} onDelete={() => {
                        forceSet({});
                        handleCloseMenu();
                    }} />
                ) : undefined
            }
        </Menu>
        <TableContainer>
            <Stack position="sticky" justifyContent="end" direction="row" spacing={2} sx={{ py: 2, left: 0, }}>
                {
                    canAddUser ? <CreateUserButton onCreated={() => {
                        forceSet({});
                    }} /> : undefined
                }
                <IconButton onClick={toggleFilter}>
                    {
                        isFiltersShowed ? <ShownFilterIcon color="secondary" /> : <HiddenFilterIcon color="secondary" />
                    }
                </IconButton>
                {
                    isFiltersShowed ? (
                        <>
                            <Divider orientation="vertical" sx={{ opacity: 0.6 }} variant="middle" flexItem />
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="body2">Роль пользователя:</Typography>
                                <Select
                                    labelId="role-label"
                                    label="Роль пользователя"
                                    id="role-select"
                                    value={roleFilter}
                                    onChange={roleChanged}
                                    multiple
                                    displayEmpty
                                    input={<Input disableUnderline id="select-multiple-chip" />}
                                    renderValue={(selected) => (
                                        selected.length === 0 || selected.length === 3 ? "Все" : selected.map(roleToLabel).join(", ")
                                    )}
                                >
                                    {[UserRole.user, UserRole.manager, UserRole.admin].map((status) => (
                                        <MenuItem
                                            key={status}
                                            value={status}
                                            selected={roleFilter.includes(status)}
                                        >
                                            {roleFilter.includes(status) ? <SelectedIcon sx={{ mr: 1 }} /> : undefined}
                                            {roleToLabel(status)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                            <Divider orientation="vertical" sx={{ opacity: 0.6 }} flexItem />
                            <TextField
                                id="users-text-filter-search"
                                placeholder="Поиск..."
                                type="search"
                                variant="standard"
                                value={textFilter}
                                onChange={textFilterChanged}
                            />
                        </>
                    ) : undefined
                }
            </Stack>
            <Table
                stickyHeader
                aria-labelledby="пользователи"
                size="small"
            >
                <UsersTableHead
                    onSortChanged={sortChanged}
                    orderBy={sort.strategy}
                    reverse={sort.reverse} />
                <TableBody>
                    {
                        data?.result !== undefined ? (
                            data.result.users.map(user => {
                                return (
                                    <TableRow sx={rowStyles} data-user-id={user.id} key={user.id}>
                                        <TableCell>{user.username}</TableCell>
                                        <TableCell align="center">{roleToLabel(user.role)}</TableCell>
                                        <TableCell sx={{ minWidth: 300 }}>{user.email}</TableCell>
                                        <TableCell sx={{ minWidth: 200 }}>{user.fio}</TableCell>
                                        {
                                            Object.values(canActions).some(id => id) ? (
                                                <TableCell>
                                                    <IconButton onClick={onRowActionsOpened} data-user={user.id}>
                                                        <MoreIcon />
                                                    </IconButton>
                                                </TableCell>
                                            ) :undefined
                                        }
                                    </TableRow>
                                );
                            })
                        ) : undefined
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {
            data?.result !== undefined && data.result.users.length === 0 ? (
                <Stack>
                    <Box height="20vmin" />
                    <Typography variant="h5" align="center" color="secondary">Список пуст</Typography>
                    <Box height="20vmin" />
                </Stack>
            ) : undefined
        }
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            {
                data !== null && data.result === undefined ? (
                    <Typography variant="caption" color="error">{data.error.mainMessage}</Typography>
                ) : undefined
            }
            {
                loading ? (
                    <CircularProgress size="1rem" sx={{ width: 15, height: 15 }} color="secondary" />
                )  : undefined
            }
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={data?.result !== undefined ? data.result.total : -1}
                rowsPerPage={take}
                page={page}
                onPageChange={pageChanged}
                onRowsPerPageChange={takeChanged}
                labelRowsPerPage="Количество пользователей в таблице:"
                labelDisplayedRows={({ from, to }) => `${from}-${to}`}
            />
        </Stack>
    </Stack>;
};

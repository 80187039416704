import React from "react";
import { IChatRecord } from "../../models";
import { ChatRecordComponent } from "./chat-record";
import { InView } from "react-intersection-observer";
import { CircularProgress } from "@mui/material";
import { GetRecordsDirection } from "../../services";
import { useAppSelector } from "../../hooks";

export interface IChatChunk {
    keys: [symbol, symbol],
    records: IChatRecord[];
    isTopBorder: boolean;
    isBottomBorder: boolean;
    isActive: boolean;
}

export interface IChatChunkProps {
    chunk: IChatChunk;

    loadMore: (direction: GetRecordsDirection) => void;
    viewUntil: (until: IChatRecord["id"]) => void;

    loading: boolean;

    lastIViewed: Date | null;
    lastOtherViewed: Date | null;
}

export const ChatChunk = (props: IChatChunkProps) => {
    const myId = useAppSelector(state => state.session.token !== undefined ? state.session.userId : undefined);

    return (
        <>
            {
                !props.chunk.isTopBorder ? (
                    <InView as="div" onChange={(view) => {
                        if (view && !props.loading) {
                            props.loadMore(GetRecordsDirection.backward);
                        }
                    }}>
                        <CircularProgress onClick={() => props.loadMore(GetRecordsDirection.backward)} />
                    </InView>
                ) : undefined
            }
            {
                props.chunk.records.map((record) => {
                    const mine = record.userId === myId;

                    if (!record.viewed) {
                        if (
                            (mine && props.lastOtherViewed !== null && props.lastOtherViewed >= record.sendAt) ||
                            (props.lastIViewed !== null && props.lastIViewed >= record.sendAt)
                        ) {
                            record.viewed = true;
                        }
                    }

                    const recordComponent = <ChatRecordComponent record={record} key={record.id} mine={mine} />;

                    if (!mine && !record.viewed) {
                        return <InView as="div" onChange={(view) => {
                            if (view) {
                                props.viewUntil(record.id);
                            }
                        }} key={record.id}>
                            {recordComponent}
                        </InView>;
                    }

                    return recordComponent;
                })
            }
            {
                !props.chunk.isBottomBorder ? (
                    <InView as="div" onChange={(view) => {
                        if (view && !props.loading) {
                            props.loadMore(GetRecordsDirection.forward);
                        }
                    }}>
                        <CircularProgress onClick={() => props.loadMore(GetRecordsDirection.forward)} />
                    </InView>
                ) : undefined
            }
        </>
    );
};

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CircularProgress, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import { IAppResponse, NOT_FOUND, SOMETHING_HAPPENED_WRONG_ERROR } from "../../utils";
import { ChatRecordType, IChat, IChatRecord } from "../../models";
import { GetRecordsDirection, IChatHandler, parseRecord, ServicesContext } from "../../services";

import SendIcon from "@mui/icons-material/SendRounded";
import { ChatChunk, IChatChunk } from "./chat-chunk";
import { useAppSelector } from "../../hooks";

export interface IChatProps {
    chatId: IChat["id"];
}

const defaultGetMessages = 10;

function mergeChunks(chunks: IChatChunk[]): IChatChunk[] {
    const result: IChatChunk[] = [];

    for (const chunk of chunks) {
        if (result.length === 0) {
            result.push(chunk);
        } else {
            const previous = result[result.length - 1];
            let merge = false;
            let mergeIndex = -1;
        
            if (previous.records.length !== 0 && chunk.records.length !== 0) {
                for (let i = 0; i < chunk.records.length; i++) {
                    if (previous.records[previous.records.length - 1].id == chunk.records[i].id) {
                        merge = true;
                        mergeIndex = i;
                        break;
                    }
                }
            } else {
                merge = true;
            }

            if (merge) {
                const leftKey = typeof(previous.keys) === "symbol" ? previous.keys : previous.keys[0];
                const rightKey = typeof(chunk.keys) === "symbol" ? chunk.keys : chunk.keys[1];

                result[result.length - 1] = {
                    records: [
                        ...previous.records,
                        ...chunk.records.slice(mergeIndex + 1),
                    ],
                    isTopBorder: previous.isTopBorder,
                    isBottomBorder: chunk.isBottomBorder,
                    isActive: previous.isActive || chunk.isActive,
                    keys: [leftKey, rightKey],
                };
            } else {
                result.push(chunk);
            }
        }
    }

    return result;
}

type IGetChunksPromise = {
    promise: Promise<IAppResponse<IChatRecord[]>>,
    direction: GetRecordsDirection,
    relatedChunkKey: symbol | null,
};

export const ChatComponent = (props: IChatProps) => {
    const { chatsService } = useContext(ServicesContext);
    const myId = useAppSelector(state => state.session.token !== undefined ? state.session.userId : undefined);

    const [chat, setChat] = useState<IAppResponse<IChat | null> | null | undefined>(undefined);

    const [chunks, setChunks] = useState<IChatChunk[]>([]);
    const [chunkRecordsPromise, setChunkRecordsPromise] = useState<IGetChunksPromise | null>(null);
    const current = useMemo(() => chunks.find((chunk) => chunk.isActive), [chunks]);

    const [lastIViewed, setLastIViewed] = useState<Date | null>(null);
    const [lastOtherViewed, setLastOtherViewed] = useState<Date | null>(null);

    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        let isActual = true;

        chunkRecordsPromise?.promise.then((res) => {
            if (!isActual) {
                return;
            }

            if (res.result) {
                // not related to any chunk
                if (chunkRecordsPromise.relatedChunkKey !== null) {
                    const chunk = chunks.find(chunk => {
                        if (chunkRecordsPromise.direction === GetRecordsDirection.backward) {
                            return chunk.keys[0] === chunkRecordsPromise.relatedChunkKey;
                        } else {
                            return chunk.keys[1] === chunkRecordsPromise.relatedChunkKey;
                        }
                    });

                    if (chunk === undefined) {
                        return;
                    }

                    if (chunkRecordsPromise.direction === GetRecordsDirection.backward) {
                        chunk.records.unshift(...res.result);
                        chunk.isTopBorder = res.result.length < defaultGetMessages;
                        chunk.keys[0] = Symbol();
                    } else {
                        chunk.records.push(...res.result);
                        chunk.isBottomBorder = res.result.length < defaultGetMessages;
                        chunk.keys[1] = Symbol();
                    }
                } else {
                    chunks.push({
                        records: res.result,
                        keys: [Symbol(), Symbol()],
                        isActive: chunks.length === 0,
                        isTopBorder: chunkRecordsPromise.direction === GetRecordsDirection.backward && res.result.length < defaultGetMessages,
                        isBottomBorder: chunkRecordsPromise.direction === GetRecordsDirection.forward && res.result.length < defaultGetMessages,
                    });
                }

                setChunks(mergeChunks(chunks));
            }
        }).finally(() => {
            setChunkRecordsPromise(null);
        });

        return () => {
            isActual = false;
        };
    }, [chunks, chunkRecordsPromise, setChunkRecordsPromise]);

    useEffect(() => {
        if (chat === undefined) {
            setChat(null);

            (async () => {
                try {
                    const res = await chatsService.getChat(props.chatId);
                    setChat(res);
                } catch (e) {
                    setChat({
                        error: SOMETHING_HAPPENED_WRONG_ERROR,
                    });
                }
            })();
        } else if (chat && chunks.length === 0) {
            (() => {
                loadRecords(GetRecordsDirection.backward);
            })();
        }
    }, [chat]);

    useEffect(() => {
        if (chat?.result) {
            const listener: IChatHandler = (type, payload) => {
                if (chat.result && payload.chat_id !== chat.result.id) {
                    return;
                }

                switch (type) {
                case "message_send": {
                    const newMessage = parseRecord(payload);
        
                    if (chunks.length !== 0 && chunks[chunks.length - 1].isBottomBorder) {
                        chunks[chunks.length - 1].records.push(newMessage);
                    } else {
                        chunks.push({
                            records: [newMessage],
                            isActive: chunks.length === 0,
                            isTopBorder: false,
                            isBottomBorder: true,
                            keys: [Symbol(), Symbol()],
                        });
                    }

                    if (newMessage.type === ChatRecordType.action && newMessage.actionType === "chat_closed") {
                        setChat({
                            result: {
                                ...chat.result,
                                closed: true,
                            } as IChat
                        });
                    }

                    setChunks([...chunks]);
                    break;
                }
                case "viewed": {
                    const date = new Date(payload.until_record_date);
                    const userId = payload.user_id;

                    if (myId === userId) {
                        if (lastIViewed === null || date > lastIViewed) {
                            setLastIViewed(date);
                        }
                    } else {
                        if (lastOtherViewed === null || date > lastOtherViewed) {
                            setLastOtherViewed(date);
                        }
                    }
                }
                }
            };

            chatsService.listen(listener);

            return () => {
                chatsService.unlisten(listener);
            };
        }
    }, [chat, chunks, chatsService, myId, lastIViewed, setLastIViewed, lastOtherViewed, setLastOtherViewed]);

    const messageChanged = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    }, [setMessage]);

    const sendMessage = useCallback(async () => {
        if (chat?.result) {
            const trimmed = message.trim();

            if (trimmed !== "") {
                try {
                    await chatsService.sendMessage(chat.result.id, trimmed);
                    setMessage("");
                } catch {
                    // ignore
                }
            }
        }
    }, [chat, message, chatsService]);

    const viewUntil = useCallback((recordId: IChatRecord["id"]) => {
        if (chat?.result) {
            chatsService.viewUntil(chat.result.id, recordId);
        }
    }, [chat, chatsService]);

    const loadRecords = useCallback((direction: GetRecordsDirection) => {
        if (chat?.result) {
            const recordAnchor = current !== undefined && current.records.length !== 0 ? (
                direction === GetRecordsDirection.forward ? current.records[current.records.length - 1].id : current.records[0].id
            ) : undefined;

            const promise = chatsService.getRecords({
                chatId: chat.result.id,
                relativeRecordId: recordAnchor,
                includeRelativeRecord: recordAnchor === undefined,
                direction,
                take: defaultGetMessages,
            });
            
            const relatedChunkKey = current !== undefined ? (
                direction === GetRecordsDirection.backward ? current.keys[0] : current.keys[1]
            ) : null;

            setChunkRecordsPromise({
                promise,
                direction,
                relatedChunkKey,
            });
        }
    }, [chat, chatsService, current, setChunkRecordsPromise]);

    return (
        <Stack direction="column" sx={{ overflowY: "auto" }} spacing={2} flexBasis="100%">
            {
                chat ? (
                    chat.result === null ? (
                        <Typography>{NOT_FOUND.mainMessage}</Typography>
                    ) : chat.result !== undefined ? (
                        <>
                            <Stack direction="column" flexBasis="100%" spacing={1.5} sx={{ overflowY: "auto", pr: 1 }}>
                                {
                                    current !== undefined ? (
                                        <ChatChunk
                                            chunk={current}
                                            loadMore={loadRecords}
                                            viewUntil={viewUntil}
                                            loading={chunkRecordsPromise !== null}
                                            lastIViewed={lastIViewed}
                                            lastOtherViewed={lastOtherViewed} />
                                    ) : <CircularProgress />
                                }
                            </Stack>
                            {
                                !chat.result.closed ? (
                                    <>
                                        <Divider />
                                        <Stack direction="row" justifyContent="flex-start" spacing={1}>
                                            <TextField
                                                id="message"
                                                value={message}
                                                onChange={messageChanged}
                                                placeholder="Напишите сообщение..."
                                                variant="standard"
                                                multiline
                                                sx={{ flexBasis: "100%" }} />
                                            <IconButton onClick={sendMessage} sx={{ width: "40px", height: "40px" }} disabled={message.trim() === ""}>
                                                <SendIcon />
                                            </IconButton>
                                        </Stack>
                                    </>
                                ) : undefined
                            }
                        </>
                    ) : (
                        <Typography>{chat.error.mainMessage}</Typography>
                    )
                ) : (
                    <CircularProgress />
                )
            }
        </Stack>
    );
};

import { Theme } from "@emotion/react";

import { PaletteOptions, createTheme } from "@mui/material";
import { AppTheme } from "./types";

import "./fonts/Lato-Regular.ttf";
import "./fonts/Montserrat-Regular.ttf";
import createPalette from "@mui/material/styles/createPalette";

const lightTheme: PaletteOptions = {
    mode: "light",
    primary: {
        main: "#2A2B2A",
    },
    secondary: {
        main: "#c96c49",
    },
    background: {
        default: "#e8e8e8",
        paper: "#eeeeee",
        selected: "#cccccc",
    },
    text: {
        primary: "#3a3b3a",
        disabled: "rgba(58,59,58,0.8)",
        secondary: "#43300c",
    },
};

const darkTheme: PaletteOptions = {
    mode: "dark",
    primary: {
        main: "#b6b6bb",
    },
    secondary: {
        main: "#dc8362",
    },
    error: {
        main: "#ff6d7c",
    },
    warning: {
        main: "#f5912e",
    },
    background: {
        default: "#111111",
        paper: "#181818",
        selected: "#444444",
    },
    text: {
        primary: "#cccccc",
        disabled: "rgba(204,204,204,0.8)",
        secondary: "#eabb6a",
    },
};

export function createAppTheme(theme: AppTheme): Theme {
    const palette = createPalette(
        theme === AppTheme.light ? lightTheme : darkTheme
    );

    const scrollbarBodyColor = theme === AppTheme.dark ? palette.primary.dark : palette.background.paper;
    

    return createTheme({
        palette,
        typography: {
            fontFamily: "Montserrat",
            button: {
                fontFamily: "Lato",
            },
            h1: {
                fontFamily: "Lato",
                fontWeight: "bold",
            },
            h2: {
                fontFamily: "Lato",
                fontWeight: "bold",
            },
            h3: {
                fontFamily: "Lato",
                fontWeight: "bold",
            },
            h4: {
                fontFamily: "Lato",
                fontWeight: "bold",
            },
            h5: {
                fontFamily: "Lato",
                fontWeight: "bold",
            },
            h6: {
                fontFamily: "Lato",
                fontWeight: "bold",
            },
            subtitle1: {
                fontFamily: "Lato",
            },
            subtitle2: {
                fontFamily: "Lato",
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                tn: 400,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        scrollbarColor: `${palette.primary.light} ${scrollbarBodyColor}`,
                        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                            backgroundColor: `${scrollbarBodyColor}`,
                        },
                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                            borderRadius: 8,
                            backgroundColor: `${palette.primary.light}`,
                            minHeight: 24,
                            border: `3px solid ${scrollbarBodyColor}`,
                        },
                        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                            backgroundColor: "#959595",
                        },
                        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                            backgroundColor: "#959595",
                        },
                        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#959595",
                        },
                        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                            backgroundColor: `${scrollbarBodyColor}`,
                        },
                        ".MuiMultiSectionDigitalClockSection-root": {
                            paddingRight: 75,
                        },
                        // react-datetime-range picker style overrides
                        ".rdrCalendarWrapper": {
                            backgroundColor: "transparent",
                        },
                        ".rdrMonthAndYearPickers select": {
                            color: `${palette.text.primary}`,
                        },
                        ".rdrMonthAndYearPickers select:hover": {
                            color: `${palette.text.primary}`,
                            backgroundColor: `${palette.background.paper}`,
                        },
                        ".rdrNextPrevButton": {
                            backgroundColor: "transparent",
                        },
                        ".rdrNextPrevButton:hover": {
                            backgroundColor: `${palette.background.selected}`,
                        },
                        ".rdrPprevButton i": {
                            borderColor: `transparent ${palette.secondary.light} transparent transparent`,
                        },
                        ".rdrNextButton i": {
                            borderColor: `transparent transparent transparent ${palette.secondary.light}`,
                        },
                        ".rdrWeekDay": {
                            color: `${palette.text.disabled}`,
                        },
                        ".rdrDay": {
                            color: `${palette.primary.main}`,
                        },
                        ".rdrDayNumber span": {
                            color: `${palette.text.primary}`,
                        },
                        ".rdrDayToday .rdrDayNumber span:after": {
                            backgroundColor: `${palette.secondary.main}`,
                        },
                        ".rdrDayPassive .rdrDayNumber span, .rdrDayDisabled .rdrDayNumber span": {
                            color: `${palette.text.disabled}`,
                            opacity: 0.5,
                        },
                        ".rdrDayDisabled": {
                            backgroundColor: "transparent",
                        },
                        ".rdrMonthName": {
                            color: `${palette.secondary.light}`,
                        },
                        ".rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span": {
                            color: `${palette.secondary.contrastText}`,
                        },
                        ".rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after": {
                            background: `${palette.secondary.contrastText}`,
                        }
                    },
                },
            }
        }
    });
}

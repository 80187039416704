import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink, useSubmit, useNavigation, useActionData } from "react-router-dom";

import { Box, Button, Card, CardActions, CardContent, CircularProgress, IconButton, InputAdornment, Link, Stack, TextField, Typography } from "@mui/material";
import AccountIcon from "@mui/icons-material/AccountCircleRounded";

import PasswordIcon from "@mui/icons-material/KeyRounded";
import VisibilityOnIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffRounded";
import { ISignInRequest, IUsersService } from "../../services";

import validator from "validator";
import { useTitle } from "../../hooks";
import { AppRoutes } from "../../constants";
import { SubmitTarget } from "react-router-dom/dist/dom";
import { ValidationError } from "../../utils";

const validateUsername = (data: ISignInRequest["username"]) => validator.isEmail(data) || (/^[a-zA-Z0-9_]+$/).test(data) || "Допустимы почта или логин, содержащий только латиницу, цифры и _";
const validatePassword = (data: ISignInRequest["password"]) => validator.isAlphanumeric(data) || "Допустимы только латиница, цифры и _";

const usernameRules = {
    required: "Поле обязательно",
    minLength: {
        value: 3,
        message: "Минимум 3 символа"
    },
    maxLength: {
        value: 150,
        message: "Максимум 150 символов"
    },
    validate: validateUsername,
};

const passwordRules = {
    required: "Поле обязательно",
    minLength: {
        value: 3,
        message: "Минимум 3 символа"
    },
    maxLength: {
        value: 150,
        message: "Максимум 150 символов"
    },
    validate: validatePassword,
};

const defaultSignInValues: ISignInRequest = {
    username: "",
    password: "",
};

export const SignInPage = () => {
    useTitle("Войти в систему - Активный гражданин");

    const submit = useSubmit();
    const navigation = useNavigation();
    const res = useActionData() as Awaited<ReturnType<IUsersService["signIn"]>> | undefined;

    const loading = navigation.state === "submitting";

    const [obscurePassword, setObscurePassword] = useState(true);

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: {
            errors,
            isValid,
        }
    } = useForm<ISignInRequest>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultSignInValues,
    });

    useEffect(() => {
        if (res !== undefined && res.result === undefined) {
            if (res.error.mainMessage !== undefined) {
                setError("root.server", { message: res.error.mainMessage });
            }
            if (res.error instanceof ValidationError) {
                for (const key in res.error.errors) {
                    setError(key as keyof ISignInRequest, { message: res.error.errors[key as keyof ISignInRequest] });
                }
            }
        } else {
            clearErrors();
        }
    }, [res, setError, clearErrors]);

    const onSubmit = useMemo(() => handleSubmit(async (data) => {
        submit(data as unknown as SubmitTarget, {
            method: "POST",
            encType: "application/json",
        });
    }), [handleSubmit]);

    const toggleObscure = useCallback(() => {
        setObscurePassword(!obscurePassword);
    }, [obscurePassword, setObscurePassword]);

    return (
        <Card sx={{ py: 1 }} elevation={4}>
            <CardContent>
                <Typography variant="h5" align="center" sx={{ p: 1 }} >Войти в систему</Typography>
                <Stack justifyContent="flex-end" direction="row">
                    <Link component={RouterLink} to={AppRoutes.restorePassword}>Забыли пароль?</Link>
                </Stack>
                <Stack alignSelf="center" spacing={3} sx={{ px: 1, pt: 2, pb: 1 }} direction="column">
                    <Controller
                        control={control}
                        rules={usernameRules}
                        disabled={loading}
                        name="username"
                        render={({ field, fieldState }) => <TextField
                            id="login"
                            {...field}
                            label="Логин / Почта"
                            error={fieldState.error !== undefined}
                            helperText={fieldState.error?.message}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountIcon />
                                    </InputAdornment>
                                )
                            }}
                        />}
                    />
                    <Controller
                        control={control}
                        rules={passwordRules}
                        disabled={loading}
                        name="password"
                        render={({ field, fieldState }) => <TextField
                            id="password"
                            type={ obscurePassword ? "password" : "text" }
                            {...field}
                            label="Пароль"
                            error={fieldState.error !== undefined}
                            helperText={fieldState.error?.message}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PasswordIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={toggleObscure}>
                                            { obscurePassword ? <VisibilityOffIcon /> : <VisibilityOnIcon /> }
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />}
                    />
                    {
                        errors?.root?.server !== undefined
                            ? (<Typography align="right" variant="caption" color="error">{errors?.root.server.message}</Typography>)
                            : undefined
                    }
                </Stack>
            </CardContent>
            <CardActions sx={{ pb: 2, pr: 2 }}>
                <Box flexGrow={1} />
                <Button startIcon={loading! ? <CircularProgress size={16} /> : undefined} disabled={loading || !isValid} onClick={onSubmit} variant="contained" color="secondary" size="medium">
                    Войти
                </Button>
            </CardActions>
        </Card>
    );
};

import { Menu, MenuItem, IconButton, ListItemIcon, Dialog, DialogActions, Button, DialogContent, DialogContentText } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import AccountIcon from "@mui/icons-material/AccountCircleRounded";
import PersonIcon from "@mui/icons-material/PersonRounded";
import SignOutIcon from "@mui/icons-material/LogoutRounded";
import ReportIcon from "@mui/icons-material/AssessmentRounded";
import UsersIcon from "@mui/icons-material/SupervisorAccountRounded";
import { useAppDispatch, useAppSelector } from "../hooks";
import { sessionActions } from "../store/slices";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../constants";
import { ReportsModal } from "../pages/reports/reports.page";

export const UserProfile = () => {
    const dispatch = useAppDispatch();
    const session = useAppSelector(state => state.session);

    const navigate = useNavigate();

    // menu
    const [profileRef, setProfileRef] = useState<null | HTMLElement>(null);

    const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setProfileRef(event.currentTarget);
    }, [setProfileRef]);

    const closeProfileMenuElement = useCallback(() => {
        setProfileRef(null);
    }, [setProfileRef]);

    // sign out
    const [isSignOutOpened, setSignOutOpened] = useState<boolean>(false);

    // reports
    const [isReportsOpened, setReportsOpened] = useState<boolean>(false);

    const signOut = useCallback(() => {
        dispatch(sessionActions.setSession(null));
        setSignOutOpened(false);
    }, [dispatch, setSignOutOpened]);

    const openSignOutElement = useCallback(() => {
        setSignOutOpened(true);
        closeProfileMenuElement();
    }, [setSignOutOpened, closeProfileMenuElement]);

    const closeSignOutElement = useCallback(() => {
        setSignOutOpened(false);
    }, [setSignOutOpened]);

    const redirectToUsers = useCallback(() => {
        navigate(AppRoutes.users);
    }, [navigate]);

    const openReportsModal = useCallback(() => {
        setReportsOpened(true);
        closeProfileMenuElement();
    }, [setReportsOpened, closeProfileMenuElement]);

    // elements
    const signOutElement = useMemo(() => (
        <Dialog
            open={isSignOutOpened}
            onClose={closeSignOutElement}
        >
            <DialogContent id="sign-out-dialog">
                <DialogContentText color="primary">Вы действительно хотите выйти из аккаунта?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={closeSignOutElement}>Отмена</Button>
                <Button variant="contained" color="secondary" onClick={signOut} autoFocus>Выйти</Button>
            </DialogActions>
        </Dialog>
    ), [isSignOutOpened, closeSignOutElement, signOut]);

    const reportsElement = useMemo(() => (
        <ReportsModal isOpened={isReportsOpened} setOpened={setReportsOpened} />
    ), [isReportsOpened, setReportsOpened]);

    const menuElement = useMemo(() => (
        <Menu
            anchorEl={profileRef}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id="profile-menu"
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={profileRef !== null}
            onClose={closeProfileMenuElement}
        >
            <MenuItem onClick={closeProfileMenuElement}>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                Профиль
            </MenuItem>
            {
                session.token !== undefined && session.accesses.includes("users.get") ? (
                    <MenuItem onClick={redirectToUsers}>
                        <ListItemIcon>
                            <UsersIcon />
                        </ListItemIcon>
                    Пользователи
                    </MenuItem>
                ) : undefined
            }
            {
                session.token !== undefined && session.accesses.includes("complaints.list") ? (
                    <MenuItem onClick={openReportsModal}>
                        <ListItemIcon>
                            <ReportIcon />
                        </ListItemIcon>
                    Отчеты
                    </MenuItem>
                ) : undefined
            }
            <MenuItem onClick={openSignOutElement}>
                <ListItemIcon>
                    <SignOutIcon />
                </ListItemIcon>
                Выйти
            </MenuItem>
        </Menu>
    ), [profileRef, closeProfileMenuElement]);

    return (
        <>
            <IconButton
                size="large"
                edge="end"
                color="inherit"
                onClick={openMenu}
            >
                <AccountIcon />
            </IconButton>
            { profileRef !== undefined ? menuElement : undefined }
            { signOutElement }
            { reportsElement }
        </>
    );
};

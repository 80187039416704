import React, { useCallback } from "react";
import { DateTimeRangePicker } from "../../components/date-time-range-picker";
import { Range } from "react-date-range";
import { ComplaintStatus } from "../../models";
import { Divider, Input, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { ComplaintStatusChip, statusLabelFrom } from "../../components/complaint-status-chip.component";

export interface IReportsComplaintsTabProps {
    reportsRange: Range;
    setReportsRange: (range: Range) => void;

    statusFilter: ComplaintStatus | null;
    setStatusFilter: (status: ComplaintStatus | null) => void;

    disabled: boolean;
}

const STATUS_OPTIONS: (ComplaintStatus | -1)[] = [
    ComplaintStatus.unchecked,
    ComplaintStatus.processing,
    ComplaintStatus.checked,
    ComplaintStatus.violatorFined,
    ComplaintStatus.violatorNotFound,
    -1,
];

export const ReportsComplaintsTab = (props: IReportsComplaintsTabProps) => {
    const {
        reportsRange, setReportsRange,
        statusFilter, setStatusFilter,
    } = props;

    const statusChanged = useCallback((event: SelectChangeEvent<ComplaintStatus | -1>) => {
        setStatusFilter(event.target.value !== -1 ? event.target.value as ComplaintStatus : null);
    }, [setStatusFilter]);

    return (
        <>
            <Stack gap={3}>
                <DateTimeRangePicker disabled={props.disabled} range={reportsRange} setRange={setReportsRange} />
                <Divider orientation="horizontal" />
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                    <Typography variant="body2">Тип жалобы:</Typography>
                    <Select
                        labelId="reports-status-label"
                        label="Статус жалобы"
                        id="reports-status"
                        value={statusFilter ?? -1}
                        onChange={statusChanged}
                        input={<Input disableUnderline id="select-multiple-chip" />}
                        disabled={props.disabled}
                        renderValue={(selected) => (
                            selected !== -1 ? <ComplaintStatusChip size="small" key={selected} status={selected} /> : "Все"
                        )}
                    >
                        {STATUS_OPTIONS.map((status) => (
                            <MenuItem
                                key={status}
                                value={status}
                            >
                                {status !== -1 ? statusLabelFrom(status) : "Все"}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
            </Stack>
        </>
    );
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox, Typography, TableContainer, TablePagination, Stack, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, ListItemIcon, ListItem, CircularProgress, useTheme, Select, SelectChangeEvent, MenuItem, Input, IconButton, TextField, Divider, Box, FormControlLabel } from "@mui/material";
import { ComplaintsOrderStrategy, IGetComplaintsRequest } from "../../services";
import { AppError, IAppResponse, SOMETHING_HAPPENED_WRONG_ERROR } from "../../utils";
import { ComplaintStatus, IComplaintType, IComplaintShort, IUser } from "../../models";
import moment from "moment";
import { ComplaintStatusChip, statusLabelFrom } from "../../components/complaint-status-chip.component";
import CancelablePromise, { cancelable } from "cancelable-promise";
import InlineSvg from "react-inlinesvg";
import ShownFilterIcon from "@mui/icons-material/FilterAltRounded";
import HiddenFilterIcon from "@mui/icons-material/FilterAltOffRounded";
import { useAppSelector } from "../../hooks";
import styles from "../../styles/global.module.scss";
import { UserChip } from "../../components/user-tooltip.component";

interface IComplainRow {
    type: string;
    user?: string | null;
    address: string;
    comment: string;
    status: ComplaintStatus;
    updatedAt: Date;
}

interface IComplaintColumn {
    id: keyof IComplainRow;
    label: string;
    sortBy?: ComplaintsOrderStrategy;
}

function complaintsHeaders(): IComplaintColumn[] {
    return [
        {
            id: "type",
            label: "Тип жалобы",
        },
        {
            id: "user",
            label: "Пользователь",
            sortBy: ComplaintsOrderStrategy.byUsername,
        },
        {
            id: "status",
            label: "Статус",
        },
        {
            id: "address",
            label: "Адрес",
            sortBy: ComplaintsOrderStrategy.byAddress,
        },
        {
            id: "comment",
            label: "Комментарий",
        },
        {
            id: "updatedAt",
            label: "Дата последнего изменения",
            sortBy: ComplaintsOrderStrategy.byDate,
        }
    ];
}

interface IComplaintsTableProps {
    onSortChanged: (strategy: ComplaintsOrderStrategy, reverse: boolean) => void;
    reverse: boolean;
    orderBy: ComplaintsOrderStrategy;
}

function ComplaintsTableHead(props: IComplaintsTableProps) {
    const { orderBy, reverse, onSortChanged } = props;

    const order = reverse ? "desc" : "asc";
  
    return (
        <TableHead>
            <TableRow>
                {complaintsHeaders().map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.sortBy ? order : false}
                        align="center"
                    >
                        {
                            headCell.sortBy !== undefined ? (
                                <TableSortLabel
                                    active={orderBy === headCell.sortBy}
                                    direction={orderBy === headCell.sortBy ? order : "asc"}
                                    onClick={ () => {
                                        if (orderBy === headCell.sortBy) {
                                            onSortChanged(orderBy, !reverse);
                                        } else {
                                            onSortChanged(headCell.sortBy!, reverse);
                                        }
                                    }}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            ) : headCell.label
                        }

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export type IGetComplaintsPageProps = {
    title: string,
    loader: (req: IGetComplaintsRequest) => Promise<IAppResponse<{
        complaints: IComplaintShort[],
        total: number,
        users?: IUser[],
        types: Record<IComplaintType["id"], IComplaintType | undefined>,
    }>>,
    complaintRowClicked: (complaintId: IComplaintShort["id"]) => any,
}

const STATUS_OPTIONS: (ComplaintStatus | -1)[] = [
    ComplaintStatus.unchecked,
    ComplaintStatus.processing,
    ComplaintStatus.checked,
    ComplaintStatus.violatorFined,
    ComplaintStatus.violatorNotFound,
    -1
];

export const GetComplaintsPage = (props: IGetComplaintsPageProps) => {
    const userId = useAppSelector(state => state.session.token?.token ? state.session.userId : undefined);
    const theme = useTheme();

    const rowStyles = useMemo(() => (
        {
            "&:nth-of-type(even)": {
                backgroundColor: theme.palette.background.paper,
            },
            "&:hover td": {
                backgroundColor: `${theme.palette.background.selected} !important`,
                transition: "0.25s ease-in-out",
                transitionProperty: "background-color",
            },
        }
    ), [theme]);

    const [isFiltersShowed, setFiltersShowed] = useState(false);

    const [take, setTake] = useState(10);
    const [page, setPage] = useState(0);
    const [assignId, setAssignId] = useState<IUser["id"] | undefined>(undefined);
    const [statusFilter, setStatusFilter] = useState<ComplaintStatus | -1>(-1);
    const [textFilter, setTextFilter] = useState("");

    const [loading, setLoading] = useState(false);
    const [promise, setPromise] = useState<CancelablePromise<Awaited<ReturnType<IGetComplaintsPageProps["loader"]>>> | null>(null);
    const [data, setData] = useState<Awaited<ReturnType<IGetComplaintsPageProps["loader"]>> | null>(null);

    const [sort, setSort] = useState({
        strategy: ComplaintsOrderStrategy.byDate,
        reverse: false,
    });

    const takeChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTake(Number(e.target.value));
    }, [setTake]);

    const pageChanged = useCallback((_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    }, [setPage]);

    const sortChanged = useCallback((strategy: ComplaintsOrderStrategy, reverse: boolean) => {
        setSort({
            strategy,
            reverse,
        });
    }, [setSort]);
    
    const assignIdChanged = useCallback(() => {
        if (assignId !== undefined) {
            setAssignId(undefined);
        } else {
            setAssignId(userId);
        }
    }, [assignId, setAssignId]);
    
    const statusChanged = useCallback((event: SelectChangeEvent<ComplaintStatus | -1>) => {
        setStatusFilter(event.target.value as ComplaintStatus | -1);
    }, [setStatusFilter]);

    const textFilterChanged = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTextFilter(e.target.value);
    }, [setTextFilter]);

    const toggleFilter = useCallback(() => {
        setFiltersShowed(!isFiltersShowed);
    }, [isFiltersShowed, setFiltersShowed]);

    const rowClicked = useCallback((e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const complaintId = e.currentTarget.getAttribute("data-complaint-id");
        if (complaintId !== null) {
            props.complaintRowClicked(complaintId);
        }
    }, []);

    useEffect(() => {
        if (promise !== null) {
            promise.cancel();
        }

        setLoading(true);

        const trimmedTextFilter = textFilter.trim();

        const loadPromise = cancelable<Awaited<ReturnType<IGetComplaintsPageProps["loader"]>>>(
            props.loader({
                skip: page * take,
                take,
                orderBy: sort.strategy,
                reverse: sort.reverse,
                assignId,
                statusFilter: statusFilter !== -1 ? statusFilter : undefined,
                textFilter: trimmedTextFilter.length === 0 ? undefined : trimmedTextFilter,
            })
        );

        loadPromise.then((res) => {
            setData(res);
        }).catch((e) => {
            if (e instanceof AppError) {
                setData({
                    error: e,
                });
            } else {
                console.error(e);
                setData({
                    error: SOMETHING_HAPPENED_WRONG_ERROR,
                });
            }
        }).finally(() => {
            setLoading(false);
            setPromise(null);
        });

        setPromise(loadPromise);
    }, [take, page, assignId, statusFilter, textFilter, sort.reverse, sort.strategy]);

    return (
        <>
            <Typography variant="h4">{props.title}</Typography>
            <TableContainer>
                <Stack position="sticky" justifyContent="end" direction="row" spacing={2} sx={{ py: 2, left: 0, }}>
                    <IconButton onClick={toggleFilter}>
                        {
                            isFiltersShowed ? <ShownFilterIcon color="secondary" /> : <HiddenFilterIcon color="secondary" />
                        }
                    </IconButton>
                    {
                        isFiltersShowed ? (
                            <>
                                <Divider orientation="vertical" sx={{ opacity: 0.6 }} variant="middle" flexItem />
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="body2">Тип жалобы:</Typography>
                                    <Select
                                        labelId="status-label"
                                        label="Статус жалобы"
                                        id="status-select"
                                        value={statusFilter}
                                        onChange={statusChanged}
                                        input={<Input disableUnderline id="select-multiple-chip" />}
                                        renderValue={(selected) => (
                                            selected !== -1 ? <ComplaintStatusChip size="small" key={selected} status={selected} /> : "Все"
                                        )}
                                    >
                                        {STATUS_OPTIONS.map((status) => (
                                            <MenuItem
                                                key={status}
                                                value={status}
                                            >
                                                {status !== -1 ? statusLabelFrom(status) : "Все"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Stack>
                                <Divider orientation="vertical" sx={{ opacity: 0.6 }} flexItem />
                                <FormControlLabel control={<Checkbox onChange={assignIdChanged} checked={assignId !== undefined} name="assign" />} label="Рассматриваемые мною" />
                                <Divider orientation="vertical" sx={{ opacity: 0.6 }} flexItem />
                                <TextField
                                    id="complaint-text-filter-search"
                                    placeholder="Поиск..."
                                    type="search"
                                    variant="standard"
                                    value={textFilter}
                                    onChange={textFilterChanged}
                                />
                            </>
                        ) : undefined
                    }
                </Stack>
                <Table
                    stickyHeader
                    aria-labelledby="жалобы"
                    size="small"
                >
                    <ComplaintsTableHead
                        onSortChanged={sortChanged}
                        orderBy={sort.strategy}
                        reverse={sort.reverse} />
                    <TableBody>
                        {
                            data?.result !== undefined ? (
                                data.result.complaints.map(complaint => {
                                    const type = data.result.types[complaint.complaintTypeId];
                                    return (
                                        <TableRow sx={rowStyles} data-complaint-id={complaint.id} onClick={rowClicked} key={complaint.id}>
                                            <TableCell>
                                                {
                                                    data.result.types[complaint.complaintTypeId] !== undefined ? (
                                                        <ListItem>
                                                            {
                                                                type !== undefined && type.icon !== undefined ? (
                                                                    <ListItemIcon color="primary">
                                                                        <InlineSvg fill={theme.palette.primary.main} width={25} height={25} className={styles.unsetFill} src={type.icon} />
                                                                    </ListItemIcon>
                                                                ) : undefined
                                                            }
                                                            { type?.title }
                                                        </ListItem>
                                                    ) : complaint.complaintTypeId
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <UserChip userId={complaint.userId} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <ComplaintStatusChip status={complaint.status} />
                                            </TableCell>
                                            <TableCell sx={{ minWidth: 300 }}>{complaint.address}</TableCell>
                                            <TableCell sx={{ minWidth: 200 }}>{complaint.comment}</TableCell>
                                            <TableCell align="center" sx={{ minWidth: 150, }}>{moment(complaint.updatedAt ?? complaint.createdAt).format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : undefined
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                data?.result !== undefined && data.result.complaints.length === 0 ? (
                    <Stack>
                        <Box height="20vmin" />
                        <Typography variant="h5" align="center" color="secondary">Список пуст</Typography>
                        <Box height="20vmin" />
                    </Stack>
                ) : undefined
            }
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                {
                    data !== null && data.result === undefined ? (
                        <Typography variant="caption" color="error">{data.error.mainMessage}</Typography>
                    ) : undefined
                }
                {
                    loading ? (
                        <CircularProgress size="1rem" sx={{ width: 15, height: 15 }} color="secondary" />
                    )  : undefined
                }
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={data?.result !== undefined ? data.result.total : -1}
                    rowsPerPage={take}
                    page={page}
                    onPageChange={pageChanged}
                    onRowsPerPageChange={takeChanged}
                    labelRowsPerPage="Количество жалоб в таблице:"
                    labelDisplayedRows={({ from, to }) => `${from}-${to}`}
                />
            </Stack>
        </>
    );
};

import React, { useCallback, useContext, useEffect, useState } from "react";
import { CircularProgress, Dialog, IconButton, Link, Paper, Stack, Typography } from "@mui/material";
import { MediaType } from "../constants";

import ImageIcon from "@mui/icons-material/ImageRounded";
import VideoIcon from "@mui/icons-material/VideocamRounded";
import AudioIcon from "@mui/icons-material/AudiotrackRounded";
import FileIcon from "@mui/icons-material/InsertDriveFileRounded";
import ErrorIcon from "@mui/icons-material/ReplayRounded";

import ReactPlayer from "react-player";
import MuiAudioPlayer from "mui-audio-player-plus";
import { SvgIconComponent } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fullscreenMediaActions } from "../store/slices";
import { IMediasService, ServicesContext } from "../services";

export interface IMediaComponentProps {
    type: MediaType;
    id: string;
    src: string;
    name: string;
    notInFullscreen: boolean;
}

export interface IMediaGroupComponentProps {
    idPrefix: string;
    type: MediaType;
    medias: {
        src: string;
        name: string
    }[]
}

export const MediaComponent = (props: IMediaComponentProps) => {
    const dispatch = useAppDispatch();
    const { mediasService } = useContext(ServicesContext);

    const [media, setMedia] = useState<Awaited<ReturnType<IMediasService["getMedia"]>> | undefined | null>(undefined);

    const loadMedia = useCallback(async () => {
        setMedia(null);
        const media = await mediasService.getMedia(props.src);
        setMedia(media);
    }, [setMedia]);

    useEffect(() => {
        if (media === undefined) {
            loadMedia();
        }
    }, [media, loadMedia]);

    if (media === undefined || media === null) {
        return (
            <Paper>
                <CircularProgress />
            </Paper>
        );
    }

    if (media.result === undefined || media.result === null) {
        return (
            <Paper>
                <IconButton onClick={loadMedia} ><ErrorIcon /></IconButton>
            </Paper>
        );
    }

    switch (props.type) {
    case MediaType.image: {
        return (
            <>            
                <img src={media.result} loading="lazy" style={{ height: props.notInFullscreen ? "300px" : "80vmin" }} onClick={props.notInFullscreen ? () => dispatch(fullscreenMediaActions.setMedia({
                    src: props.src,
                    name: props.name,
                    type: MediaType.image,
                })) : undefined} />
            </>
        );
    }
    case MediaType.video: {
        return (
            <ReactPlayer height="300px"
                controls
                id={props.id}
                url={media.result}
            />
        );
    }
    case MediaType.audio: {
        return (
            <MuiAudioPlayer
                id={props.id}
                showTimestamps={false}
                inline
                src={media.result}
            />
        );
    }
    default: {
        return (
            <Link href={props.src} download={props.name} underline="none" target="_blank" style={{ justifyContent: "start", display: "block" }} rel="noreferrer">
                <Paper elevation={3} sx={{ p: 1 }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <FileIcon color="primary" fontSize="large" />
                        <Typography fontWeight="bold" overflow="ellipsis">{props.name}</Typography>
                    </Stack>
                </Paper>
            </Link>
        );
    }
    }
};

const mediaTypeToTitle: Record<MediaType, string> = {
    [MediaType.image]: "Изображения",
    [MediaType.video]: "Видео",
    [MediaType.audio]: "Аудио",
    [MediaType.file]: "Файлы"
};

const mediaTypeToIcon: Record<MediaType, SvgIconComponent> = {
    [MediaType.image]: ImageIcon,
    [MediaType.video]: VideoIcon,
    [MediaType.audio]: AudioIcon,
    [MediaType.file]: FileIcon,
};

export const MediaGroup = (props: IMediaGroupComponentProps) => {
    const Icon = mediaTypeToIcon[props.type];

    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={1} sx={{ pt: 3 }}>
                <Icon />
                <Typography variant="subtitle2">
                    {mediaTypeToTitle[props.type]} ({props.medias.length})
                </Typography>
            </Stack>
            <Stack direction={props.type === MediaType.audio || props.type === MediaType.file ? "column" : "row"} spacing={2} sx={{ overflowX: "auto", py: 1 }} alignItems="stretch">
                {
                    props.medias.map(((media, index) => (
                        <MediaComponent
                            key={`${props.type}-${index}`}
                            id={`${props.idPrefix}-${props.type}-${index}`}
                            src={media.src}
                            name={media.name}
                            notInFullscreen
                            type={props.type} 
                        />
                    )))
                }
            </Stack>
        </Stack>
    );
};

export const FullscreenMedia = () => {
    const fullscreenMedia = useAppSelector((state) => state.fullscreenMedia.media);

    const dispatch = useAppDispatch();

    const handleClose = useCallback(() => dispatch(fullscreenMediaActions.clear()), []);

    if (fullscreenMedia !== undefined) {
        return (
            <Dialog
                maxWidth={false}
                open={true}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxWidth: "100% !important"
                    }
                }}
                sx={{ backgroundColor: "transparent", maxWidth: "100%" }}
            >
                <Paper>
                    <MediaComponent
                        src={fullscreenMedia.src}
                        name={fullscreenMedia.name}
                        type={fullscreenMedia.type}
                        notInFullscreen={false}
                        id="fullscreen-media" />
                </Paper>
            </Dialog>
        );
    }

    return <></>;
};

import React, { useCallback, useContext, useEffect, useState } from "react";
import { IUser } from "../models";
import { Chip, ChipProps, CircularProgress, ListItem, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import UsernameIcon from "@mui/icons-material/AccountCircleRounded";
import EmailIcon from "@mui/icons-material/EmailRounded";
import PhoneIcon from "@mui/icons-material/PhoneRounded";
import FioIcon from "@mui/icons-material/BadgeRounded";
import AddressIcon from "@mui/icons-material/HomeRounded";
import { IUsersService, ServicesContext } from "../services";
import RetryIcon from "@mui/icons-material/ReplayRounded";


export type IUserContentProps = {
    user: IUser;
    children: React.ReactElement
}

const UserLabel = (props: { icon: React.ReactNode, title: React.ReactNode, value: React.ReactNode }) => {
    return (
        <ListItem sx={{ p: 0, px: 1 }}>
            <ListItemText primary={(
                <Stack direction="row" alignItems="center" spacing={1}>
                    {props.icon}
                    <Typography variant="subtitle2"><b>{props.title}</b></Typography>
                </Stack>
            )} secondary={
                <Typography variant="body1">{props.value}</Typography>

            } />
        </ListItem>
    );
};

export const UserTooltip = (props: IUserContentProps) => {
    return (
        <Tooltip title={
            <React.Fragment>
                <UserLabel icon={<UsernameIcon />} title="Логин" value={props.user.username} />
                {
                    props.user.fio !== undefined ? (
                        <UserLabel icon={<FioIcon />} title="ФИО" value={props.user.fio} />
                    ) : undefined
                }
                {
                    props.user.email !== undefined ? (
                        <UserLabel icon={<EmailIcon />} title="Логин" value={props.user.email} />
                    ) : undefined
                }
                {
                    props.user.phone !== undefined ? (
                        <UserLabel icon={<PhoneIcon />} title="Телефон" value={props.user.phone} />
                    ) : undefined
                }
                {
                    props.user.address !== undefined ? (
                        <UserLabel icon={<AddressIcon />} title="Адрес" value={props.user.address} />
                    ) : undefined
                }
            </React.Fragment>
        } arrow>
            {
                props.children
            }
        </Tooltip>
    );
};

export interface IUserChipProps extends ChipProps {
    userId: IUser["id"];
    labelBuilder?: (user: IUser) => React.ReactNode;
}

export const UserChip = ({ userId, labelBuilder, ...props }: IUserChipProps) => {
    const usersService = useContext(ServicesContext).usersService;

    const [user, setUser] = useState<Awaited<ReturnType<IUsersService["getUser"]>> | undefined | null>(undefined);

    const loadUser = useCallback(async () => {
        setUser(null);
        const result = await usersService.getUser(userId);
        setUser(result);
    }, [usersService, setUser]);

    useEffect(() => {
        if (user === undefined) {
            loadUser();
        }
    }, [user, loadUser]);

    return (
        user === undefined || user === null ? (
            <Chip label={<CircularProgress size={10} />} {...props} />
        ) : user.result ? (
            <UserTooltip user={user.result}>
                <Chip label={ labelBuilder === undefined ? user.result.username : labelBuilder(user.result)} {...props} />
            </UserTooltip>
        ) : (
            <Chip icon={<RetryIcon />} label="Ошибка" color="error" onClick={loadUser} {...props} />
        )
    );
};

import { IUser } from "./user";

export enum ComplaintStatus {
    unchecked = 0,
    processing = 1,
    violatorFined = 2,
    violatorNotFound = 3,
    checked = 4,
}

export enum AllowedMedia {
    onlyPhoto = 1 << 0,
    onlyVideo = 1 << 1,
    onlyAudio = 1 << 2,
}

export enum AllowedPoint {
    onlyObject = 1 << 0,
    onlyFree = 1 << 1,
}

export enum FieldType {
    string = 0,
    choices = 1,
    number = 2,
    boolean = 3,
}

export type IFieldTypeRecordBase<T = any> = {
    id: string,
    type: FieldType,
    title: string,
    optional: boolean,
    default_value: T,
}

export type INumberFieldTypeRecord = IFieldTypeRecordBase<number> & {
    signed: boolean,
    allowed_decimal: boolean,
}

export type IChoiceFieldTypeRecord = IFieldTypeRecordBase<string> & {
    items: string[],
}

export interface IComplaintType {
    id: string;
    title: string;
    description?: string;
    fields: IFieldTypeRecordBase[];
    maxMedia: number;
    allowedMedia: AllowedMedia;
    allowedPoints: AllowedPoint;
    icon?: string;
}

export interface IComplaintTypeGroup {
    id: string;
    title: string;
    types: IComplaintType[],
    icon?: string;
}

export interface IComplaintShort {
    id: string;
    complaintTypeId: string;
    userId: IUser["id"];
    address: string;
    comment: string;
    status: ComplaintStatus;
    createdAt: Date;
    updatedAt?: Date;
}

export interface IComplaintFull extends IComplaintShort {
    relatedCheckpointId?: number;
    fields: Record<string, string | number | boolean>;
    lat: number;
    lon: number;
    userLat: number;
    userLon: number;
    assignedUserId?: IUser["id"];
    medias: Record<string, string | undefined>;
    chatId: string;
    closedAt?: Date;
    closedConfirmed?: boolean;
}

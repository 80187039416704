import { Tuple, combineSlices, configureStore } from "@reduxjs/toolkit";
import { createListenSessionChangedMiddleware, createListenThemeChangedMiddleware, fullscreenMediaSlice, sessionSlice, themeSlice } from "./slices";

const rootReducer = combineSlices(
    sessionSlice,
    themeSlice,
    fullscreenMediaSlice,
);

export function createAppStore() {
    return configureStore({
        reducer: rootReducer,
        devTools: true,
        middleware: (getDefault) => new Tuple(
            ...getDefault(),
            createListenSessionChangedMiddleware(),
            createListenThemeChangedMiddleware(),
        ),
    });
}

export type IAppState = ReturnType<typeof rootReducer>;
export type IAppStore = ReturnType<typeof createAppStore>;

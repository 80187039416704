import React from "react";
import { Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { IComplaintChangeReport } from "../../services";
import moment from "moment";
import { statusLabelFrom } from "../../components/complaint-status-chip.component";

export interface IReportsComplaintsPageProps {
    start: Date;
    end: Date;
    reports: IComplaintChangeReport[];
}

export const ReportsComplaintsChangesPage = (props: IReportsComplaintsPageProps) => {
    return (
        <>
            <Typography variant="h4">
                Отчет по работе с обращениями
            </Typography>
            <Typography variant="h6">За период {moment(props.start).format("DD.MM.YYYY")}-{moment(props.end).format("DD.MM.YYYY")}</Typography>

            <Divider sx={{ my: 2 }} orientation="horizontal" />

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">ID Жалобы</TableCell>
                        <TableCell align="center">Статус до</TableCell>
                        <TableCell align="center">Статус после</TableCell>
                        <TableCell sx={{ maxWidth: 200 }}>Дата</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.reports.map((report, index) => (
                            <TableRow key={`report-row-${index}`}>
                                <TableCell>{report.change.id}</TableCell>
                                <TableCell>{statusLabelFrom(report.change.beforeStatus)}</TableCell>
                                <TableCell>{statusLabelFrom(report.change.afterStatus)}</TableCell>
                                <TableCell>{moment(report.change.dt).format("DD.MM.YYYY HH:mm:ss")}</TableCell>

                                {/* <TableCell align="center" sx={{ maxWidth: 250 }}>{report.user?.fio ?? report.user?.username ?? "-"}</TableCell>
                                {
                                    reportsFields[report.id].length !== 0 ? (
                                        <TableCell>
                                            <Stack direction="column">
                                                {reportsFields[report.id].map((field: any[], index: number) => (<Box key={`report-${report.id}-${index}`}>{field[0]}: {field[1]}</Box>))}
                                            </Stack>
                                        </TableCell>
                                    ) : <TableCell align="center">-</TableCell>
                                }
                                <TableCell sx={{ maxWidth: 200 }}>{report.address} <br /> ({report.latitude}, {report.longitude})</TableCell> */}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </>
    );
};

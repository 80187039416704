export enum AppTheme {
    light = "light",
    dark = "dark",
}

export enum AppThemeMode {
    light = "light",
    dark = "dark",
    system = "system"
}

import { IComplaintShort } from "../models";

const routesPrefix = process.env["REACT_APP_ROUTES_PREFIX"] ?? "";

export class AppRoutes {
    static readonly root = routesPrefix;
    static readonly signIn = `${routesPrefix}/sign-in`;
    static readonly restorePassword = `${routesPrefix}/restore-password`;
    static readonly home = `${routesPrefix}/home`;
    static complaintRoot = `${routesPrefix}/complaints/:id`;
    static complaint(complaintId: IComplaintShort["id"]): string {
        return `${routesPrefix}/complaints/${complaintId}`;
    }
    static readonly users = `${routesPrefix}/users`;
}

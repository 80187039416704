import React from "react";
import { Outlet } from "react-router-dom";
import { AppBar, Box, Container, Stack, Toolbar, useTheme } from "@mui/material";
import { IRootProps, Root } from "./unauthorized-root";
import { UserProfile } from "../../components/user-profile.component";
import { ReactComponent as TalismanIcon } from "../../assets/icons/talisman.svg";
import { NotificationsButton } from "../../components/notifications-button.component";

export const AuthorizedRoot = (props: IRootProps) => {
    const theme = useTheme();

    return (
        <Root sx={{ overflow: "auto" }}>
            <AppBar elevation={0} position="sticky">
                <Toolbar>
                    <TalismanIcon fill={theme.palette.primary.main} height={30} width={30} />
                    <Box flexGrow={1} />
                    <Stack direction="row" spacing={0.5}>
                        <NotificationsButton />
                        <UserProfile />
                    </Stack>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl" sx={{ px: 2, py: 5, display: "flex", flexDirection: "column", flexBasis: "100%", overflow: "auto" }}>
                {props.outlet ?? <Outlet />}
            </Container>
        </Root>
    );
};

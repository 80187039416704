import React from "react";
import { CardHeader, Typography, useTheme } from "@mui/material";
import { IComplaintFull, IComplaintType } from "../../../../models";
import InlineSvg from "react-inlinesvg";
import globalStyles from "../../../../styles/global.module.scss";

export interface IComplaintCardHeaderProps {
    complaint: IComplaintFull,
    type: IComplaintType,
}

export const ComplaintCardHeader = ({ complaint, type }: IComplaintCardHeaderProps) => {
    const theme = useTheme();

    return (
        <CardHeader avatar={
            type.icon !== undefined ? (
                <InlineSvg fill={theme.palette.primary.main} className={globalStyles.unsetFill} width={25} height={25} src={type.icon} />
            ) : undefined
        } title={<Typography variant="h6">{type.title}</Typography>} subheader={`# ${complaint.id}`} />
    );
};

import { Dispatch } from "@reduxjs/toolkit";
import { ISignInRequest, IUsersService } from "../../../services";
import { sessionActions } from "../../../store/slices";
import { AppRoutes } from "../../../constants";
import { ActionFunction, redirect } from "react-router-dom";

export function signInAction(usersService: IUsersService, dispatch: Dispatch): ActionFunction {
    return async (args) => {
        const data: ISignInRequest = await args.request.json();
        const res = await usersService.signIn(data);
        if (res.result !== undefined) {
            dispatch(sessionActions.setSession(res.result));
            return redirect(AppRoutes.home);
        }
        return res;
    };
}

import React, { useMemo } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import moment from "moment";

import AddressIcon from "@mui/icons-material/LocationOnRounded";
import CoordsIcon from "@mui/icons-material/GpsFixedRounded";
import CreatedAtIcon from "@mui/icons-material/CalendarTodayRounded";
import UpdatedAtIcon from "@mui/icons-material/EditCalendarRounded";
import ClosedAtIcon from "@mui/icons-material/EventBusyRounded";

import { IComplaintFull, IComplaintType } from "../../../../models";
import { extensionToMediaType, groupBy } from "../../../../utils";
import { MediaGroup } from "../../../../components/media.component";

const ComplaintLabel = (props: { icon: React.ReactNode, title: React.ReactNode, value: React.ReactNode }) => {
    return (
        <Stack direction="column" spacing={0.25} sx={{ py: 1 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
                {props.icon}
                <Typography variant="subtitle2"><b>{props.title}</b></Typography>
            </Stack>
            <Typography variant="body2">{props.value}</Typography>
        </Stack>
    );
};

export interface IComplaintCardContentProps {
    complaint: IComplaintFull,
    type: IComplaintType,
}

export const ComplaintCardContent = ({ complaint, type }: IComplaintCardContentProps) => {
    const fields: [string, string | number | boolean][] = useMemo(() => {
        const complaintFieldsList = Array.from(Object.entries(complaint.fields));
        const typeFieldsMap = new Map(type.fields.map(field => [field.id, field]));

        return complaintFieldsList.map(([key, value]) => (
            [typeFieldsMap?.get(key)?.title ?? key, value]
        ));
    }, [complaint, type]);

    const medias = useMemo(() => {
        return groupBy(Object.keys(complaint.medias), (media) => extensionToMediaType(media.split(".").pop() ?? ""));
    }, [complaint]);

    return (
        <>
            <ComplaintLabel icon={<AddressIcon />} title="Адрес" value={complaint.address} />
            <ComplaintLabel icon={<CoordsIcon />} title="Координаты" value={`${complaint.lat}, ${complaint.lon}`} />
            <ComplaintLabel icon={<CreatedAtIcon />} title="Дата создания" value={moment(complaint.createdAt).format("DD/MM/YYYY HH:mm:ss")} />
            {
                complaint.updatedAt ? (
                    <ComplaintLabel icon={<UpdatedAtIcon />} title="Дата последнего изменения" value={moment(complaint.updatedAt).format("DD/MM/YYYY HH:mm:ss")} />
                ) : undefined
            }
            {
                complaint.closedAt ? (
                    <ComplaintLabel icon={<ClosedAtIcon />} title="Дата завершения рассмотрения" value={moment(complaint.closedAt).format("DD/MM/YYYY HH:mm:ss")} />
                ) : undefined
            }
            {
                fields.length !== 0 ? (
                    <>
                        <Divider orientation="horizontal" flexItem variant="middle" />
                        {
                            fields.map(([key, value]) => (
                                <Typography sx={{ py: 0.5 }} key={key as string}><b>{key}</b>: {value}</Typography>
                            ))
                        }
                        <Divider orientation="horizontal" flexItem variant="middle" />
                    </>
                )  : undefined
            }
            <Typography variant="body2" color="primary.light">{complaint.comment}</Typography>
            {
                medias.size !== 0
                    ? (
                        <>
                            <Divider orientation="horizontal" flexItem variant="middle" sx={{ pt: 2 }} />
                            {
                                Array.from(medias.entries()).filter(entry => entry[0] != null).map(([type, medias]) => (
                                    <MediaGroup key={type} medias={medias.map((media) => ({
                                        src: media,
                                        name: complaint.medias[media]!,
                                    }))} type={type!} idPrefix="complaint" />
                                ))
                            }
                        </>
                    ) : undefined
            }
        </>
    );
};

import React, { useMemo } from "react";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { ChatRecordType, IChatActionRecord, IChatMessageRecord, IChatRecord } from "../../models";

import TickIcon from "@mui/icons-material/DoneAll";
import { UserChip } from "../../components/user-tooltip.component";
import { statusLabelFrom } from "../../components/complaint-status-chip.component";
import { extensionToMediaType, groupBy } from "../../utils";
import { MediaGroup } from "../../components/media.component";

export interface IChatRecordComponentProps<T extends IChatRecord = IChatRecord> {
    record: T,
    mine: boolean,
}

const MessageRecord = (props: IChatRecordComponentProps<IChatMessageRecord>) => {
    const medias = useMemo(() => {
        return groupBy(Object.keys(props.record.medias), (media) => extensionToMediaType(media.split(".").pop() ?? ""));
    }, [props.record.medias]);

    return (
        <>
            <Box alignSelf="flex-start">
                { !props.mine ? <UserChip userId={props.record.userId} size="small" sx={{ mt: 1 }} /> : undefined }
            </Box>
            <Box borderRadius={1} bgcolor={props.mine ? "primary.main" : "background.selected"} color={props.mine ? "secondary.contrastText" : "text.primary"} sx={{ px: 1.5, py: 1, width: "max-content", maxWidth: "40%", alignSelf: props.mine ? "flex-end" : "flex-start" }}>
                <Stack spacing={1}>
                    <Typography variant="subtitle2">{props.record.message}</Typography>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                        <Typography variant="caption" align="right">{props.record.sendAt.toLocaleTimeString()}</Typography>
                        { props.mine && props.record.viewed ? <TickIcon sx={{ width: "15px", height: "15px" }} /> : undefined }
                    </Stack>
                </Stack>
            </Box>
            {
                medias.size !== 0
                    ? (
                        <>
                            <Divider orientation="horizontal" flexItem variant="middle" sx={{ pt: 2 }} />
                            {
                                Array.from(medias.entries()).filter(entry => entry[0] != null).map(([type, medias]) => (
                                    <MediaGroup key={type} medias={medias.map((media) => ({
                                        src: media,
                                        name: props.record.medias[media]!,
                                    }))} type={type!} idPrefix="complaint" />
                                ))
                            }
                        </>
                    ) : undefined
            }
        </>

    );
};

function actionTypeToLabel(actionType: string) {
    if (actionType.startsWith("complaint_change_status_")) {
        const status = Number(actionType.replace("complaint_change_status_", ""));
        return `Статус жалобы изменен на "${statusLabelFrom(status)}"`;
    }

    switch (actionType) {
    case "chat_closed": {
        return "Обсуждение закрыто и заархивировано";
    }
    }

    return actionType;
}

const ActionRecord = (props: IChatRecordComponentProps<IChatActionRecord>) => {
    return (
        <Box alignSelf="center">
            <Chip label={actionTypeToLabel(props.record.actionType)} />
        </Box>
    );
};

export const ChatRecordComponent = (props: IChatRecordComponentProps) => {
    switch (props.record.type) {
    case ChatRecordType.message: {
        return (
            <MessageRecord record={props.record} mine={props.mine} />
        );
    }
    case ChatRecordType.action: {
        return (
            <ActionRecord record={props.record} mine={props.mine} />
        );
    }
    default: {
        return (
            <Chip label="Неопределенный тип сообщения" />
        );
    }
    }
};

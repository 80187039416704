import React from "react";
import { Chip, Divider, Stack, TextField, Typography } from "@mui/material";
import { DateRange, Range } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ruLocale from "date-fns/locale/ru";
import moment, { Moment } from "moment";
import { TimePicker } from "@mui/x-date-pickers";
import "@mui/x-date-pickers/AdapterMoment";
import { SlotComponentPropsFromProps } from "@mui/x-date-pickers/internals";
import { capitalizeFirstLetter } from "../utils/capitalize-first";

export interface IReportsModalProps {
    range: Range;
    setRange: (range: Range) => void;
    disabled: boolean;
}

const defaultDateRanges: ({ key: string, title: string, create: () => [Date, Date] })[] = [
    {
        key: "today",
        title: "Сегодня",
        create: () => {
            const now = moment();
            const begin = now.startOf("day").toDate();
            const end = now.endOf("day").toDate();
            return [begin, end];
        },
    },
    {
        key: "yesterday",
        title: "Вчера",
        create: () => {
            const now = moment();
            now.subtract(1, "day");
            const begin = now.startOf("day").toDate();
            const end = now.endOf("day").toDate();
            return [begin, end];
        }
    },
    {
        key: "current-week",
        title: "На этой неделе",
        create: () => {
            const now = moment();
            const end = now.endOf("day").toDate();
            const startWeek = now.startOf("isoWeek").toDate();
            return [startWeek, end];
        }
    },
    {
        key: "last-week",
        title: "На прошлой неделе",
        create: () => {
            const now = moment();
            const lastWeek = now.subtract(7, "day");
            const startWeek = lastWeek.startOf("isoWeek").toDate();
            const endWeek = lastWeek.endOf("isoWeek").toDate();
            return [startWeek, endWeek];
        }
    },
    {
        key: "last-7-days",
        title: "Последние 7 дней",
        create: () => {
            const now = moment();
            const end = now.endOf("day").toDate();
            const begin = now.subtract(6, "day").startOf("day").toDate();
            return [begin, end];
        }
    },
    {
        key: "current-month",
        title: "Текущий месяц",
        create: () => {
            const now = moment();
            const end = now.endOf("day").toDate();
            const begin = now.startOf("month").toDate();
            return [begin, end];
        }
    },
    {
        key: "last-month",
        title: "Предыдущий месяц",
        create: () => {
            const now = moment();
            now.subtract(1, "month");
            const begin = now.startOf("month").toDate();
            const end = now.endOf("month").toDate();
            return [begin, end];
        }
    },
];

function mergeDateTime(dateMoment: Moment, timeMoment: Moment): Moment {
    const year = dateMoment.year();
    const month = dateMoment.month();
    const day = dateMoment.date();

    const hours = timeMoment.hours();
    const minutes = timeMoment.minutes();
    const seconds = timeMoment.seconds();
    const milliseconds = timeMoment.milliseconds();

    const combinedMoment = moment({
        year: year,
        month: month,
        day: day,
        hour: hours,
        minute: minutes,
        second: seconds,
        millisecond: milliseconds,
    });

    return combinedMoment;
}

function changeDays(range: Range, newRange: Range): Range {
    return {
        startDate: mergeDateTime(moment(newRange.startDate!), moment(range.startDate!)).toDate(),
        endDate: mergeDateTime(moment(newRange.endDate!), moment(range.endDate!)).toDate(),
        key: range.key,
        color: range.color,
    };
}

const datePickerFieldVariants: SlotComponentPropsFromProps<typeof TextField, object, Record<string, any>> = {
    variant: "standard",
    size: "large",
    InputProps: {
        disableUnderline: true,
    },
    inputProps: {
        style: {
            width: "5ch",
            borderBottom: "none",
        },
    },
};

const dateRender = {
    sameDay: "MMMM YYYY",
    nextDay: "MMMM YYYY",
    lastDay: "MMMM YYYY",
    nextWeek: "MMMM YYYY",
    lastWeek : "MMMM YYYY",
    sameElse : "MMMM YYYY"
};

export const DateTimeRangePicker = ({range, setRange, disabled}: IReportsModalProps) => {
    return (
        <Stack direction="row" gap={2}>
            <Stack direction="column" gap={1}>
                {
                    defaultDateRanges.map(item => (
                        <Chip
                            key={item.title}
                            onClick={() => {
                                const [startDate, endDate] = item.create();
                                setRange({
                                    startDate,
                                    endDate,
                                    key: range.key,
                                    color: range.color,
                                });
                            }}
                            variant="filled"
                            size="small"
                            label={item.title}
                            color="secondary"
                            disabled={disabled}
                        />
                    ))
                }
            </Stack>
            <Stack direction="column" gap={1}>
                <Stack direction="row" gap={3} alignItems="center" justifyContent="center">
                    <Stack alignItems="center" direction="row" gap={2}>
                        <Typography variant="h5"><b>{range.startDate!.getDate().toString().padStart(2, "0")}</b></Typography>
                        <Stack>
                            <Typography variant="body2">{capitalizeFirstLetter(moment(range.startDate).calendar(null, dateRender))}</Typography>
                            <TimePicker disabled={disabled} slotProps={{ textField: datePickerFieldVariants }} value={moment(range.startDate)} onChange={(newTime) => {
                                setRange({
                                    ...range,
                                    startDate: mergeDateTime(moment(range.startDate), newTime as Moment).toDate(),
                                });
                            }} />
                        </Stack>
                    </Stack>
                    <Divider orientation="vertical" />
                    <Stack alignItems="center" direction="row" gap={2}>
                        <Typography variant="h5"><b>{range.endDate!.getDate().toString().padStart(2, "0")}</b></Typography>
                        <Stack>
                            <Typography variant="body2">{capitalizeFirstLetter(moment(range.endDate).calendar(null, dateRender))}</Typography>
                            <TimePicker disabled={disabled} slotProps={{ textField: datePickerFieldVariants }} value={moment(range.endDate)} onChange={(newTime) => {
                                setRange({
                                    ...range,
                                    endDate: mergeDateTime(moment(range.endDate), newTime as Moment).toDate(),
                                });
                            }} />
                        </Stack>
                    </Stack>                 
                </Stack>
                <DateRange
                    ranges={[range]}
                    onChange={({reportsRange: newReportsRange}) => !disabled && setRange(changeDays(range, newReportsRange))}
                    months={2}
                    locale={ruLocale}
                    maxDate={new Date()}
                    editableDateInputs={!disabled}
                    showPreview={!disabled}
                    showMonthArrow={!disabled}
                    showMonthAndYearPickers={!disabled}
                    dragSelectionEnabled={!disabled}
                    calendarFocus="backwards"
                    showDateDisplay={false}
                    preventSnapRefocus={true}
                    direction="horizontal" />
            </Stack>
        </Stack>
    );
};

import React, { useContext, useEffect, useRef, useState } from "react";
import { Dialog, DialogContent, DialogActions, Button, Box, Tabs, Tab, useTheme, CircularProgress, Stack, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useCallback } from "react";
import { Range } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import "@mui/x-date-pickers/AdapterMoment";
import { ReportsComplaintsTab } from "./reports-complaints-tab";
import { ComplaintStatus } from "../../models";
import { ReportsComplaintsStatusChangesTab } from "./reports-complaints-status-tab";
import CancellationToken from "cancellationtoken";
import { ServicesContext } from "../../services";
import { ReportsComplaintsPage } from "./reports-complaints.page";
import { useReactToPrint } from "react-to-print";
import { ReportsComplaintsChangesPage } from "./reports-complaints-changes";

enum ReportStrategy {
    byDate = "by-date",
    byStatusChanges = "by-status-changes",
}

function reportsStrategyToString(strategy: ReportStrategy): string {
    switch (strategy) {
    case ReportStrategy.byDate: {
        return "Обращения за период";
    }
    case ReportStrategy.byStatusChanges: {
        return "Работа с обращениями";
    }
    }
}

export interface IReportsModalProps {
    isOpened: boolean;
    setOpened: (value: boolean) => void;
}

const reportsStrategies = [ReportStrategy.byDate, ReportStrategy.byStatusChanges];

function createInitRangeState(color: string): Range {
    const now = moment();
    const startDate = now.startOf("day").toDate();
    const endDate = now.endOf("day").toDate();
    return {
        startDate,
        endDate,
        key: "reportsRange",
        color,
    };
}

export const ReportsModal = (props: IReportsModalProps) => {
    const theme = useTheme();
    const services = useContext(ServicesContext);
    const reportRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({ content: () => reportRef.current });

    const [reportsStrategy, setReportsStrategy] = useState<ReportStrategy>(ReportStrategy.byDate);
    const [reportsRange, setReportsRange] = useState<Range>(createInitRangeState(theme.palette.primary.main));
    const [statusFilter, setStatusFilter] = useState<ComplaintStatus | null>(null);
    const [reportElement, setReportElement] = useState<JSX.Element | null>(null);
    const [error, setError] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [cancellationToken, setCancellationToken] = useState<ReturnType<typeof CancellationToken.create> | null>(null);

    const cancel = useCallback(() => {
        setLoading(false);
        if (cancellationToken !== null) {
            cancellationToken.cancel();
            setCancellationToken(null);
        }
    }, [setLoading, cancellationToken, setCancellationToken]);

    const closeReportsForm = useCallback(() => {
        if (cancellationToken !== null) {
            cancel();
        }
        props.setOpened(false);
    }, [props, cancellationToken, cancel]);

    const closeReport = useCallback(() => {
        setReportElement(null);
    }, [setReportElement]);

    const generateReport = useCallback(async () => {        
        setLoading(true);
        setError(null);

        const cancellationToken = CancellationToken.create();

        try {
            setCancellationToken(cancellationToken);

            switch(reportsStrategy) {
            case ReportStrategy.byDate: {
                const reports = await services.reportsService.byDate(
                    reportsRange.startDate!,
                    reportsRange.endDate!,
                    statusFilter ?? undefined,
                    cancellationToken.token,
                );

                if (!cancellationToken.token.isCancelled) {
                    if (reports.length !== 0) {
                        setReportElement((
                            <ReportsComplaintsPage start={reportsRange.startDate!} end={reportsRange.endDate!} statusFilter={statusFilter} reports={reports} />
                        ));
    
                        closeReportsForm();
                    } else {
                        setError("Отчет не содержит данные, выберите другой временной интервал");
                    }
                }

                break;
            }
            case ReportStrategy.byStatusChanges: {
                const reports = await services.reportsService.byStatusChanges(
                    reportsRange.startDate!,
                    reportsRange.endDate!,
                    cancellationToken.token,
                );

                if (!cancellationToken.token.isCancelled) {
                    if (reports.length !== 0) {
                        setReportElement((
                            <ReportsComplaintsChangesPage start={reportsRange.startDate!} end={reportsRange.endDate!} reports={reports} />
                        ));
    
                        closeReportsForm();
                    } else {
                        setError("Отчет не содержит данные, выберите другой временной интервал");
                    }
                }

                break;
            }
            }
        } catch (e: any) {
            setError(e.toString());
        } finally {
            setLoading(false);
            if (!cancellationToken.token.isCancelled) {
                cancellationToken.cancel();
            }
            setCancellationToken(null);
        }
    }, [reportsStrategy, statusFilter, reportsRange, services, closeReportsForm, setLoading, setCancellationToken, setReportElement]);

    useEffect(() => {
        if (props.isOpened) {
            return () => {
                setReportsRange(createInitRangeState(theme.palette.secondary.main));
                setStatusFilter(null);
                setReportsStrategy(ReportStrategy.byDate);
                setError(null);
            };
        }
    }, [props.isOpened, setReportsRange, setStatusFilter, setReportsStrategy]);

    return (
        <>
            <Dialog
                maxWidth="lg"
                open={props.isOpened}
                onClose={closeReportsForm}
            >
                <DialogContent id="reports-form">
                    <TabContext value={reportsStrategy}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={reportsStrategy} onChange={(_, value) => setReportsStrategy(value)} aria-label="basic tabs example">
                                {
                                    reportsStrategies.map((strategy) => (
                                        <Tab disabled={loading} key={strategy} value={strategy} label={reportsStrategyToString(strategy)} />
                                    ))
                                }
                            </Tabs>
                        </Box>
                        <TabPanel value={ReportStrategy.byDate}>
                            <ReportsComplaintsTab
                                reportsRange={reportsRange} setReportsRange={setReportsRange}
                                statusFilter={statusFilter} setStatusFilter={setStatusFilter}
                                disabled={loading}
                            />
                        </TabPanel>
                        <TabPanel value={ReportStrategy.byStatusChanges}>
                            <ReportsComplaintsStatusChangesTab
                                reportsRange={reportsRange} setReportsRange={setReportsRange}
                                disabled={loading}
                            />
                        </TabPanel>
                    </TabContext>
                </DialogContent>
                <DialogActions>
                    {
                        error != null ? <Typography color="error" variant="caption">{error}</Typography> : undefined
                    }
                    <Button color="secondary" onClick={closeReportsForm}>Отмена</Button>
                    <Button disabled={loading} variant="contained" color="secondary" onClick={generateReport} autoFocus>
                        <Stack gap={1} direction="row" alignItems="center">
                            {loading ? (<CircularProgress size={15} />) : undefined}
                    Составить
                        </Stack>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                maxWidth="lg"
                open={reportElement !== null}
                onClose={closeReport}>
                <DialogContent id="report" ref={reportRef}>
                    {reportElement}                        
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="text" onClick={closeReport}>Закрыть</Button>
                    <Button color="secondary" variant="contained" onClick={handlePrint}>Печать</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

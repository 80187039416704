import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import BackIcon from "@mui/icons-material/ArrowBackRounded";

export type IGoBackButton = {
    navigateToWhenNoHistory?: string;
};

export const GoBackButton = (props: IGoBackButton) => {
    const navigate = useNavigate();
    const canGoBack = window.history.state && window.history.state.idx > 0;

    const goBack = useCallback(() => {
        if (canGoBack) {
            navigate(-1);
        } else if (props.navigateToWhenNoHistory !== undefined) {
            navigate(props.navigateToWhenNoHistory);
        }
    }, [navigate, canGoBack]);

    return (
        <Button onClick={goBack} variant="text" size="small" startIcon={<BackIcon />}>Назад</Button>
    );
};

import React, { useContext, useMemo } from "react";
import { RouterProvider, createBrowserRouter, redirect } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { AppRoutes } from "../../constants";
import { SignInPage } from "../sign-in/sign-in.page";
import { ErrorHandler } from "./error-handler";
import { UnauthorizedRoot } from "./unauthorized-root";
import { AuthorizedRoot } from "./authorized-root";
import { HomePage } from "../home/home.page";
import { useStore } from "react-redux";
import { IServices, ServicesContext } from "../../services";
import { IAppState, IAppStore } from "../../store/store";
import { redirectIfAuthorized, redirectIfUnauthorized } from "../../utils";
import { restorePasswordAction, signInAction } from "./actions";
import { ComplaintPage } from "../complaint/complaint.page";
import { RestorePasswordPage } from "../sign-in/restore-password.page";
import { requireAccessAction } from "./loaders";
import { UsersPage } from "../users/users.page";

function createAppBrowserRouter(store: IAppStore, services: IServices): ReturnType<typeof createBrowserRouter> {
    const dispatch = store.dispatch;
    const { session } = store.getState();

    const authorized = session.token !== undefined;

    const redirectAuthorized = () => redirectIfAuthorized(authorized);
    const redirectUnauthorized = () => redirectIfUnauthorized(authorized);
    const RootElement = authorized ? AuthorizedRoot : UnauthorizedRoot;

    return createBrowserRouter([
        {
            element: (<RootElement />),
            errorElement: (<RootElement outlet={<ErrorHandler />} />),
            children: [
                {
                    path: "/",
                    loader: () => redirect(authorized ? AppRoutes.home : AppRoutes.signIn),
                },
                {
                    path: AppRoutes.root,
                    index: true,
                    loader: () => redirect(authorized ? AppRoutes.home : AppRoutes.signIn),
                },
                {
                    path: AppRoutes.restorePassword,
                    loader: redirectAuthorized,
                    action: restorePasswordAction(services.usersService),
                    element: (<RestorePasswordPage />)
                },
                {
                    path: AppRoutes.signIn,
                    loader: redirectAuthorized,
                    action: signInAction(services.usersService, dispatch),
                    element: (<SignInPage />),
                },
                {
                    path: AppRoutes.home,
                    loader: redirectUnauthorized,
                    element: (<HomePage />),
                },
                {
                    path: AppRoutes.complaintRoot,
                    loader: redirectUnauthorized,
                    element: (<ComplaintPage />),
                },
                {
                    path: AppRoutes.users,
                    loader: requireAccessAction(session, "users.get"),
                    element: (<UsersPage />),
                },
            ],
        },
    ]);
}

export const AppRouter = () => {
    const store = useStore<IAppState>();
    const session = useAppSelector(state => state.session);
    const services = useContext(ServicesContext);

    const router = useMemo(() => createAppBrowserRouter(store, services), [session]);

    return (
        <RouterProvider router={router} />
    );
};
